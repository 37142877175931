.cart__item {
    width: 25%;
}

.cart__img {
    width: 22%;
}

.cart .main-title {
    padding: 0px 15px;
}

.cart__color-holder {
    text-transform: uppercase;
}

.cart__color,
cart__size,
.cart__quantiy-box,
.cart__price {
    width: 14%;
}

.cart {
    margin-bottom: 25px;
}

.cart__img-holder img {
    width: 100%;
    cursor: pointer;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    font-weight: normal;
    font-size: 16px !important;
    color: rgba(26, 26, 26, 255);
    font-family: Montserrat-light !important;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

input.cart__loyalty-input {
    padding: 10px;
    width: 235px;
    font-size: 14px;
    font-family: Montserrat-light;
    margin-bottom: 22px;
    border: 1px solid #CCCCCC;
}

.cart__item-holder h3 {
    /* color: red; */
    font-family: Montserrat-Medium;
    font-size: 18px;
    color: rgba(0, 0, 0, 255);
    text-transform: uppercase;
    text-align: c;
    margin-bottom: 10px;
}

.cart__item-holder p {
    font-family: Montserrat-Light;
    font-size: 14px;
    /* color: rgba(0, 0, 0, 255); */
}

.cart__table td {
    font-weight: normal;
    font-family: Montserrat-Light;
    font-size: 18px;
    padding-bottom: 20px !important;
    color: rgba(0, 0, 0, 255);
    text-align: left;
}

.cart__quantity-holder a {
    font-family: Montserrat-Light;
    font-size: 12px;
    color: rgba(153, 153, 153, 255);
    text-align: center;
    text-transform: capitalize;
    margin-top: 0;
    float: left;
    width: 100%;
}

input.cart__loyalty-input {
    padding: 10px;
    width: 235px;
    font-size: 14px;
    font-family: Montserrat-light;
    border: 1px solid #CCCCCC;
}

.cart__quantity-holder h3 {
    margin-bottom: 18px;
}

.cart__loyalty-input ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff !important;
}

.cart__loyalty-input ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff !important;
}

.cart__loyalty-input :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff !important;
}

.cart__loyalty-input :-moz-placeholder {
    /* Firefox 18- */
    color: #fff !important;
}

button.cart__btn-cheack.btn.btn-default {
    background: #999999;
    border: 0;
    border-radius: 0;
    padding: 9px;
    color: #fff;
    border-color: #ccc;
    border: 1px solid #ccc;
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    width: 150px;
}

.cart__price-holder h3 {
    font-family: Montserrat-Medium;
    font-size: 18px;
    color: rgba(0, 0, 0, 255);
    /* text-align: center; */
    /* font-weight: bold; */
}

div.cart-total {
    width: 100%;
    font-family: Montserrat-Medium;
    font-size: 24px;
    padding: 20px 0px;
    border-top: 1px solid #999999;
    text-align: right;
    color: rgba(0, 0, 0, 255);
}

input.quantity__btn.form-group {
    border: 2px solid #cccccc;
    width: 79px;
    text-align: center;
    height: 38px;
    text-indent: 9px;
    margin-bottom: 8px;
}

.cart__continue button {
    border-color: #ccc;
    border-radius: 0;
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    height: 40px;
    width: 200px;
}

button.cart__btn-continue.btn.btn-default {
    background: #999999;
    margin-right: 20px;
}

button.cart__btn-payment.btn.btn-default {
    background: #000;
}

.cart__continue {
    float: right;
}

button.cart__btn-continue.btn.btn-default:hover,
button.cart__btn-continue.btn.btn-default:focus,
button.cart__btn-cheack.btn.btn-default:focus,
button.cart__btn-cheack.btn.btn-default:hover {
    background: white;
    color: #999999;
    transition: all 0.8s ease;
    border-color: #ccc;
}

button.cart__btn-payment.btn.btn-default:hover,
button.cart__btn-payment.btn.btn-default:focus {
    background: white;
    color: #000000;
    transition: all 0.8s ease;
}

.cart__quantity-holder {
    margin-top: 22px;
    position: relative;
}

.cart__img-holder {
    margin-top: 22px;
}

hr.cart-hr {
    border-top: 1px solid #999999;
    margin-bottom: 30px;
}

table.table h3 {
    font-family: Montserrat-Light;
    font-size: 18px;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 255);
}

.cart__price-holder p {
    font-size: 14px;
    line-height: 19px;
    font-family: Montserrat-light !important;
}

.cart__item-holder h3 {
    font-family: Montserrat-Medium !important;
    font-size: 18px !important;
    color: rgba(0, 0, 0, 255);
}

.cart__quantity-holder a:hover {
    color: red;
    /* font-weight: bold; */
    transition: all 0.8s ease;
}

.table>thead>tr>th {
    border-bottom: 1px solid #999999 !important;
}