.Login {
  color: #000;
}
input.login-now__btn-password.creat__placeholder {
  margin-bottom: 0;
}

.creat-account_holder:after {
  border-right: 2px solid #b3b3b3;
  position: absolute;
  content: '';
  height: 100%;
  background: #b3b3b3;
  right: 43px;
  height: 396px;
  top: 0;
  margin-top: -8px;
}
.creat-account__caption p,
.login-now__caption p {
  font-family: Montserrat-Regular;
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.creat-account__caption h3,
.login-now__caption h3 {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 0;
  color: #000;
}

.creat-account__btn-holder p,
.login-now__btn-holder p {
  font-size: 14px;
  font-family: Montserrat-light;
  font-size: 14px;
  margin-bottom: 12px;
}

.creat-account__btn-holder input,
.login-now__btn-holder input {
  height: 36px;
  width: 240px;
  padding: 13px;
  margin-bottom: 18px;
  border: 2px solid #d9d9d9;
}
button.login-now__submit-btn:hover,
button.creat-account__submit-btn:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}

.login-now__forgot-button {
  border: none;
  color: #000;
  font-size: 14px;
  font-family: Montserrat-light;
  background-color: transparent;
  
}

.login-now__forgot-button:disabled {
  cursor: not-allowed;
 
}

button.login-now__submit-btn:focus,
button.creat-account__submit-btn:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

button.creat-account__submit-btn,
button.login-now__submit-btn {
  background: #000;
  width: 148px;
  height: 41px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-Regular;
  font-size: 14px;
  margin: 0;
}
button.login-now__submit-btn {
  width: 88px;
}

.creat-account__btn-holder sup,
.login-now__btn-holder sup {
  top: 0.1em;
  font-size: 14px;
  margin-left: 1px;
}

::-webkit-input-placeholder {
  font-family: Montserrat-light;
  font-size: 14px;
}
.creat__placeholder:-moz-placeholder {
  font-family: Montserrat-light;
}
::-moz-placeholder {
  font-size: 14px;
  line-height: 30px;
}
:-ms-input-placeholder {
  font-family: Montserrat-light;
  font-size: 14px;
}

.login-now__forgot-password {
  margin-top: 9px;
  margin-bottom: 17px;
}

.login-now__forgot-password a {
  color: #000;
  font-size: 13px;
  font-family: Montserrat-light;
}

.user-info__btn-holder p {
  font-size: 14px;
  font-family: Montserrat-light;
  font-size: 14px;
  margin-bottom: 12px;
}

.user-info__btn-holder input {
  height: 36px;
  width: 240px;
  padding: 13px;
  margin-bottom: 18px;
  border: 2px solid #d9d9d9;
}

.user-info sup {
  top: 0.1em;
  font-size: 14px;
  margin-left: 1px;
}

button.user-info__btn-cancle,
button.user-info__btn-save {
  background: #000;
  width: 98px;
  height: 41px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-light;
  font-size: 14px;
  margin: 0;
  outline: 0;
}

button.user-info__btn-cancle {
  background: #b7b7b7;
  width: 78px;
  height: 41px;
  margin-left: 51px;
  margin-right: 13px;
  outline: 0;
}

button.user-info__btn-cancle:hover,
button.user-info__btn-save:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}

button.user-info__btn-cancle:focus,
button.user-info__btn-save:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

.user-adress__shipping__caption h3 {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: rgba(0, 0, 0, 255);
  margin: 0;
  margin-bottom: 25px;
}

.user-adress__shipping__btn-holder p {
  font-size: 14px;
  font-family: Montserrat-light;
  font-size: 14px;
  margin-bottom: 12px;
}

.user-adress__shipping__btn-holder input {
  height: 36px;
  width: 240px;
  padding: 13px;
  margin-bottom: 18px;
  border: 2px solid #d9d9d9;
}

.user-adress__shipping__btn-holder sup {
  top: 0.1em;
  font-size: 14px;
  margin-left: 1px;
}

button.user-adress__btn__cancle-shipping:focus,
button.user-adress__btn__save-shipping:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

button.user-adress__btn__cancle-shipping {
  background: #b7b7b7;
  width: 78px;
  height: 41px;
  margin-left: 51px;
  margin-right: 13px;
  outline: 0;
}

button.user-adress__btn__cancle-shipping:hover,
.user-adress__btn__save-shipping:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}

button.user-adress__btn__cancle-shipping,
button.user-adress__btn__save-shipping {
  background: #000;
  height: 41px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-light;
  font-size: 14px;
  margin: 0;
  outline: 0;
}
button.user-adress__btn__cancle-shipping {
  width: 78px;
  margin-left: 30px;
  margin-right: 13px;
}
button.user-adress__btn__save-shipping {
  width: 118px;
  background: #b7b7b7;
}

.user-adress__billing__caption h3 {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  color: rgba(0, 0, 0, 255);
  margin: 0;
  margin-bottom: 25px;
}

.user-adress__billing__btn-holder p {
  font-size: 14px;
  font-family: Montserrat-light;
  font-size: 14px;
  margin-bottom: 12px;
}

.user-adress__billing__btn-holder input {
  height: 36px;
  width: 240px;
  padding: 13px;
  margin-bottom: 18px;
  border: 2px solid #d9d9d9;
}

.user-adress__billing__btn-holder sup {
  top: 0.1em;
  font-size: 14px;
  margin-left: 1px;
}

button.user-adress__btn__cancle-billing:focus,
button.user-adress__btn__save-billing:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

button.user-adress__btn__cancle-billing {
  background: #b7b7b7;
  width: 78px;
  height: 41px;
  margin-left: 51px;
  margin-right: 13px;
  outline: 0;
}

button.user-adress__btn__cancle-billing:hover,
.user-adress__btn__save-billing:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}

button.user-adress__btn__cancle-billing,
button.user-adress__btn__save-billing {
  background: #000;
  height: 41px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-light;
  font-size: 14px;
  margin: 0;
  outline: 0;
}
button.user-adress__btn__cancle-billing {
  width: 78px;
  margin-left: 30px;
  margin-right: 13px;
}
button.user-adress__btn__save-billing {
  width: 118px;
  background: #b7b7b7;
}

.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}

.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}

input.user-newsletter__checkbox[type='radio'] {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
input.user-newsletter__checkbox[type='radio'],
input.user-newsletter__checkbox[type='radio'] {
  height: 1.3em;
  width: 1.4em;
  vertical-align: middle;
  margin: 0 0.4em 0.4em 0;
  background: transparent;
  -webkit-appearance: none;
  -webkit-transition: box-shadow 200ms;
  margin-top: 5px;
}
.control input.user-newsletter__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control--checkbox .control__indicator:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #cccccc;
  position: absolute;
  top: 2px;
  left: 2px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.user-newsletter__controler.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background: #e6e6e6;
  border: 2px solid #d9d9d9;
  background: transparent;
}

label.user-newsletter__label.control.control--checkbox {
  font-family: Montserrat-light;
  font-size: 14px;
  color: rgba(0, 0, 0, 255);
  font-weight: 400;
  margin-bottom: 19px;
  margin-top: 23px;
  line-height: 18px;
}
.user-newsletter__caption {
  font-family: Montserrat-light;
  font-size: 14px;
  width: 542px;
}

button.user-newsletter__btn__cancle:focus,
button.user-newsletter__btn__save-changes:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

button.user-newsletter__btn__cancle {
  background: #b7b7b7;
  width: 78px;
  height: 41px;
  margin-left: 51px;
  margin-right: 13px;
  outline: 0;
}

button.user-newsletter__btn__cancle:hover,
.user-newsletter__btn__save-changes:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}

button.user-newsletter__btn__cancle,
button.user-newsletter__btn__save-changes {
  background: #000;
  height: 41px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-light;
  font-size: 14px;
  margin: 0;
  outline: 0;
}
button.user-newsletter__btn__cancle {
  width: 78px;
  margin-left: 30px;
  margin-right: 13px;
}
button.user-newsletter__btn__save-changes {
  width: 118px;
  background: #b7b7b7;
}

.user-newsletter__caption p {
  margin-bottom: 22px;
}

.loyalty-point__caption p,
.loyalty-point__code p {
  font-family: Montserrat-light;
  font-size: 14px;
  margin-bottom: 16px;
}

.loyalty-point__caption h3,
.loyalty-point__code h3 {
  font-size: 36px;
  margin-bottom: 14px;
  font-family: Montserrat-Medium;
  margin: 0;
  margin-bottom: 24px;
}

.loyalty-point__code h3 {
  margin-bottom: 29px;
}

button.user-loyalty-point__btn-save.btn.btn-default {
  background: #000;
  height: 41px;
  width: 229px;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-family: Montserrat-light;
  font-size: 14px;
  margin: 0;
  outline: 0;
}

button.user-loyalty-point__btn-save.btn.btn-default:hover {
  background: #fff;
  color: #000;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
}
button.user-loyalty-point__btn-save.btn.btn-default:focus {
  background: #999;
  color: #fff;
  transition: all 0.7s ease;
  border: 1px solid #ccc;
  outline: 0;
}

.Login {
  margin-bottom: 60px;
}

.notification.notification-error {
  background: #db2815;
  padding: 18px 50px;
  line-height: 22px;
  z-index: 2;
}

.notification.notification-succsess {
  background: #30bd9d;
  padding: 18px 50px;
  line-height: 22px;
  z-index: 2;
}

.Login__caption p {
  font-family: Montserrat-light;
  font-size: 14px;
  color: #000;
  text-align: center;
  margin-bottom: 40px;
  width: 580px;
  margin-left: 35px;
}
.verifyModal {
  width: 50%;
  margin: 0 auto;
}
.verifyModal .pinInput {
  height: 41px;
  text-align: center;
  padding: 0 auto;
  width: 100%;
}
