.user-account-information__checkout input,
.user-accountــaddress__checkout input {
    width: 100%;

}

input.shipping-box-active {
    width: 20px;
    height: 20px;
    background: black;
    margin: 0px;
    margin-right: 10px
}

p.terms {
    margin-left: 30px;
    padding: 0px;
}

input.shipping-box {
    width: 20px;
    height: 20px;
    padding: 7px 11px !important;
    background: white !important;
    margin: 0px;
    margin-right: 10px
}

input.shipping-box-active:focus {
    padding: 7px 11px;
}

span.terms-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.shipping-modal h5 {
    text-decoration: underline;
}

.user-account__line__checkout button.dropdown-toggle.btn.btn-default {
    width: 100%;
    text-align: left;
    border-radius: 0px;
    height: 40px;
    overflow: hidden;
}

.user-account__line__checkout .select__control {
    height: 40px;
    border-radius: 0px;
}

.user-account__line__checkout .select__input input {
    margin-bottom: 0px;
}

.user-account__line__checkout .dropdown.btn-group {
    width: 100%;
}

.user-account__line__checkout ul.dropdown-menu {
    max-height: 300px;
    overflow: scroll;
}