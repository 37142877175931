/* .shop-main__shop-left{
    width: 312px;
} */

.shop-main__head {
    background: #000000;
    color: #fff;
    font-family: BaskervilleItalic;
    padding: 3px 33px;
    height: 65px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 1px 14px 0px;
}

.shop-main.for-web.header{
    margin-left: 15px;
}
.shop-main__head h3 {
    margin: 10px;
    font-size: 25px;
    line-height: 40px;
    margin-left: 0;
}

input#color-hex:after {
    content: "Choose Your Own";
}

.shop-main__shop-layered {
    border: 2px solid #b3b3b3;
    padding-bottom: 90px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 1px 14px 0px;

}

span.shop-menu__count {
    float: right;
    color: #a0a0a0;
}

.shop-main__filter-title {
    text-transform: uppercase;
    font-weight: bold;
}

.shop-main__filter-title h4 {
    margin-top: 20px;
    font-family: Montserrat-Regular;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
}

.shop-menu__item {}

.shop-main__controler.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background: #e6e6e6;
    border: 2px solid;
    background: transparent;
}

.shop-main .control input:checked~.control__indicator {
    background: #ffffff;
}

.control--checkbox .control__indicator:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #000000 !important;
    position: absolute;
    top: 2px;
    left: 2px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.shop-menu__item-color a:active {
    border: 1px solid #000000;
    margin-top: 1px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.shop-menu__item-color {
    margin-bottom: 15px;
    padding: 0 2px;
    margin-right: 0px;
    float: left;
    margin-right: 0px;
    cursor: pointer;
    margin-left: -2px;
}

.shop-menu__item-color a {
    width: 43px;
    height: 43px;
}

.row.shop-menu {
    margin: 0;
    width: 250px;
}

.shop-menu__link {
    color: #000000;
    margin-left: 1px;
    font-size: 16px;
    font-weight: bold;
}

.shop-main__menu-holder {
    margin-bottom: 30px;
}

.shop-menu__item-color img {
    width: 40px;
    height: 40px;
    padding: 2px;
    object-fit: cover;
}

a.shop-menu__choose-own {
    font-size: 10px;
    float: left;
    padding: 0px;
    border: 1px solid #b3b3b3;
    text-align: center;
    font-weight: bold;
    color: #999999;
}

button.shop-main__filter__btn {
    background: transparent;
    color: #999999 !important;
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 17px;
    border-radius: 0;
    border: 1px solid #e9e9e9;
}

button.shop-main__filter__btn:hover,
focus,
active {
    background: transparent !important;
}

.shop-main__filter-cart {
    float: right;
    margin-top: 10px;
}

.shop-main__filter-dropdown {
    width: 100%;
    border: 0;
    box-shadow: inherit;
    margin: 0;
    border: 1px solid #e9e9e9;
}

.shop-main__filter-dropdown li a {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.shop-main__filter-dropdown li a:hover {
    color: #fff;
    background: #666666 !important;
}

.select select:hover,
.select select:focus .shop-menu__select-holder {
    background: transparent !important
}


.shop-menu__select-holder .select__arrow {
    position: absolute;
    top: 16px;
    right: 13px;
    width: 14px;
    height: 24px;
    pointer-events: none;
    border-style: solid;
    /* border-width: 8px 5px 0 5px; */
    /* border-color: #7b7b7b transparent transparent transparent; */
    background: url(../../../img/shop/arrow-shop.png);
    background-repeat: no-repeat;
    border: 0;
    background-size: 16px;
    z-index: 0;
}
.shop-menu__select .select__arrow:before{
    position: absolute;
    top: -15px;
    height: 40px;
    width: 1px;
    content: "";
    background: #e9e9e9;
    left: -15px;
}

.shop-menu__dropdown span.shop-menu__size-title {
    font-family: BaskervilleItalic;
}

label.shop-main__label.control.control--checkbox {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
    font-weight: 200;
    margin-bottom: 9px;
    text-transform: uppercase;
}

.shop-menu__select select {
    background: transparent;
    border: 1px solid;
    background: transparent;
    color: #999999 !important;
    width: 100%;
    text-align: left;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid #e9e9e9;
}

.shop-menu__item-color img:active {
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 3px 7px 5px -1px rgba(0, 0, 0, 0.19);
}


.distributors-eg-box__branch-name p {
    font-family: stc !important;
    font-size: 17px !important;
    color: #131313;
    font-weight: bold !important;
}

.distributors-eg__holder.col-md-4 {
    height: 303px;
    padding: 0;
    /* border-top: 0; */
}

.shop-menu__item-color-active {
    border: 2px solid black;
}

.shop-menu__select.select {
    width: 100%;
}

/* base styles */

input[type="radio"],
input[type="checkbox"] {
    height: 1.3em;
    width: 1.4em;
    vertical-align: middle;
    margin: 0 0.4em 0.4em 0;
    border: 2px solid #202020;
    background: transparent;
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
    margin-top: 5px;
}

/* border radius for radio*/

input[type="radio"] {
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

/* border radius for checkbox */

input[type="checkbox"] {
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

/* hover state */

input[type="radio"]:not(:disabled):hover,
input[type="checkbox"]:not(:disabled):hover {
    border-color: rgba(0, 0, 0, 0.5);
}

/* active state */

input[type="radio"]:active:not(:disabled),
input[type="checkbox"]:active:not(:disabled) {
    background-image: -webkit-linear-gradient(#C2C2C2, #EFEFEF);
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.2), inset -1px -1px 0 rgba(255, 255, 255, 0.6);
    border-color: rgba(0, 0, 0, 0.5);
}

/* focus state */

input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: none;
}

/* input checked border color */

input[type="radio"]:checked,
input[type="checkbox"]:checked {
    border-color: rgba(0, 0, 0, 0.5)
}

input[type="checkbox"]:focus {
    background: #000;
    padding: 1px;
}

/* radio checked */

input[type="radio"]:checked:before {
    display: block;
    height: 0.3em;
    width: 0.3em;
    position: relative;
    left: 0.4em;
    top: 0.4em;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 100%;
    content: '';
}

/* checkbox checked */

input[type="checkbox"]:checked:before {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    -webkit-margin-start: 0;
    margin-left: 2px;
    font-size: 0.9em;
}

/* disabled input */

input:disabled {
    opacity: .6;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

/* style label for disabled input */

input:disabled+label {
    opacity: .6;
    cursor: default;
    -webkit-user-select: none;
}

.sidbar-select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.sidbar-select2 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #9999;
    width: 100%;
    border: 1px solid #e9e9e9;
    color: rgba(153, 153, 153, 255);
}

.sidbar-shop-styled {
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 8px 15px;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    font-family: Montserrat-Light;
    font-size: 14px;
}

.sidbar-shop-styled:after {
    content: "";
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 16px;
}

.sidbar-shop-styled:hover {
    background-color: transparent;
}

.sidbar-shop-styled:active,
.shop-styled.active {
    background-color: transparent;
}

.sidbar-shop-styled:active:after,
.shop-styled.active:after {
    top: 9px;
    border-color: transparent transparent #fff transparent;
}

.sidbar-select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: -1px;
    left: -1px;
    z-index: 999;
    margin: 0;
    padding: 1px;
    list-style: none;
    background-color: #fff;
    color: #000;
    border: 1px solid #e9e9e9;
}

.sidbar-select-options li {
    margin: 0;
    padding: 3px 0;
    text-indent: 15px;
    border-bottom: 1px solid #fff;
    -o-transition: all 0.15s ease-in;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    font-family: Montserrat-Light;
    font-size: 14px;
    height: 24px;
    margin-top: 4px;
}

.sidbar-select-options li:hover {
    color: #fff;
    background: #666666;
}

.sidbar-select-options li[rel="hide"] {
    display: none;
}

button.sidebar-button_more {
    padding: 7px;
    margin-top: 13px;
    background: black;
    color: #fff;
    font-size: 13px;
    border: none;
    border: 1px solid black;
    width: 100%;
    text-transform: uppercase;
}

button.sidebar-button_more.btn-layout:hover {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: #fff !important;
    color: black;
    border: 1px solid black;
}

.hiclass {
    display: block;
}

.shop-count {
    float: right;
    color: #999999;
    /* font-weight: bold; */
    font-size: 14px;
}

.btn-expand-collapse.m-responsive {
    top: 0 !important;
}

a.single-collapse__btn.btn-expand-collapse.m-responsive span.glyphicon.glyphicon-menu-left {
    color: white;
}

a.single-collapse__btn.btn-expand-collapse.m-responsive span.glyphicon-menu-right.glyphicon {
    color: white;
}

a.single-collapse__btn.btn-expand-collapse.m-responsive span.glyphicon-menu-left.glyphicon {
    -ms-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg) !important;
    color: #fff !important;
}

a.single-collapse__btn.btn-expand-collapse.m-responsive span.glyphicon-menu-right.glyphicon {
    -ms-transform: rotate(90deg) !important;
    transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg) !important;
    color: #fff !important;
}

button.sort_filter.dropdown-toggle.btn.btn-default:focus,
button.sort_filter.dropdown-toggle.btn.btn-default:hover,
button.sort_filter.dropdown-toggle.btn.btn-default:active {
    box-shadow: none;
    outline: 0;
}

.shop-menu__select.select.select select:hover,
.select select:focus {
    color: #000;
    background: #fff !important;
}

.show-result button.showroom__btn-btn.btn.btn-default {
    /* margin: 0 !important; */
    padding: 10px;
    color: #fff;
    margin-bottom: 20px !important;
    background: black;
    margin-top: 20px !important;
}

.show-result {
    text-align: center;
}

.sticky {
    top: 0;
    width: 23vw;
    transition: 1s;
}

div#myHeader {
    width: 23vw;
    transition: 1s;
    height: 100vh;
    overflow-y: scroll;
}