hr.hr-login {
    display: none;
}


    
@media (min-width:992px){
    .design-your-own__btn.for-mobile {
        display: none;
    }
}
@media (max-width:991px){
    .design-your-own__btn.large_screen {
        display: none;
    }
    .design-your-own__wrapper.row {
        margin-bottom: 45px;
        text-align: center;
    }
}
@media (min-width:769px) {
    
    button.sidebar__toggle.btn.btn-default {
        display: none;
    }
    div#collapsible-panel-example-1 {
        display: none;
    }
    .sidbar_mobile {
        display: none;
    }
    .phone-search {
        display:none;
    }
}
@media (max-width:1050px){

    section.header-menu .header-menu__important-links li a{
        margin-left: 10px;
    font-size: 9px;
    margin-top: 7px;
    }
    a.header-menu__logo.navbar-brand img {
        width: 130px;
    }
}
@media only screen and (min-width: 768px) {
    ul.menu li {
        margin-bottom: 15px;
    }
    .header-mobile_holder {
        display: none;
    }
    ul.menu {
        display: block;
    }
    .contact-carusal .slick-slider.slick-initialized{
        display:none
    }
    .user-account .slick-slider.slick-initialized{
        display:none
    }
}

@media (max-width:768px) {
    form.phone-search {
        padding: 0px 15px;
    }
    .loyaltyprogram__container-item img {
        height: auto !important;
    }
    .loyaltyrewards h3 {
        display: none;
    }
    section.loyaltyprogram label.btn.btn-default {
        margin-right: 0 !important;
    }
    .loyaltyprogram__container-item {
        height: auto !important;
    }
    .single-product .item .thumb img {
        z-index: 999999999999999999;
    }
    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
        width: 60px;
        height: 60px;
    }
    .single-product .carousel {
        z-index: 999;
    }
    .collection__full-width,
    .collection__item,
    section.collection .collection__main-item {
        width: 100%;
    }
    section.gemaa-slider .carousel-control {
        z-index: 0;
    }
    section.collection .collection__item-two img,
    .collection__item-three img,
    .collection__item-seven img,
    .collection__item-eight img {
        width: 100%;
    }
    .shop-main__shop-left {
        background: #999999;
        color: #fff;
        position: fixed;
        z-index: 3;
        width: 100%;
        bottom: 0;
    }
    .container-fluid.no-padding__mobile .col-md-3.col-sm-4 {
        padding-left: 0 !important;
        padding: 0;
    }
    /* .container-fluid.no-padding__mobile {
        padding: 0;
    } */
    .shop-main__shop-layered {
        border: 0px;
        padding-bottom: 90px;
        padding-left: 30px;
        padding-right: 30px;
        height: 70vh;
        overflow: scroll;
    }
    .shop-main__shop-left {
        background: #999999;
        color: #fff;
    }
    .shop-selector__sort-by {
        height: inherit;
        padding: 17px;
        background: transparent;
    }
    .shop-main__head {
        background: transparent;
        height: inherit;
        margin: 0px;
    }
    .sidbar_mobile .panel-body {
        margin-left: 0;
        padding: 0;
    }
    button.sidebar-button_more {
        background: transparent;
        border: 1px solid white;
    }
    button.sort_filter.dropdown-toggle.btn.btn-default {
        margin-left: 0;
    }
    .shop-selector__sortby {
        float: inherit;
        margin-left: 12px;
        font-size: 36px;
        font-weight: normal
    }
    label.shop-main__label.control.control--checkbox {
        color: #fff;
    }
    .shop-main__controler.control__indicator {
        border: 1px solid;
        color: #fff;
    }
    section.shop-selector {
        display: none;
    }
    .shop-main.for-web {
        display: none;
    }
    .header-mobile .fa {
        font-size: 20px;
        color: #131313;
    }
    section.gemaa-slider .gemaa-slide__caption-holder {
        bottom: 26% !important;
    }
    .header-mobile .navbar-collapse {
        border-top: 0;
    }
    .loyaltyprogram__holder {
        height: 410px;
        margin-bottom: 42px;
    }
    section.design-your-own .design-your-own__caption p {
        margin-bottom: 20px;
    }
    .row.contactinfo-btn p {
        text-align: left;
    }
    .contact-form input,
    .contact-form__text-area,
    .contact-form select.contact-form__country-select,
    .contact-form .interested-in.control-group {
        /* width: 100%; */
    }
    .contact-form span.enter-captcha__id {
        float: left;
    }
    .contact-us__hotline-number {
        display: none;
        margin-top: 16px;
    }
    .contact-us__right {
        text-align: center;
    }
    input.center-captcha__btn {
        margin-top: 10px;
    }
    .enter-captcha {
        margin-top: 13px !important;
    }
    button.contact-form__btn-submit.btn.btn-default {
        float: left !important;
        text-align: left;
    }
}

@media (max-width:768px) {
    .grid-product-wrapper {
        margin-left: 14px;
        height: auto !important;
    }
    .grid-product__colors-img {
        margin-bottom: 10px;
        margin-top: 5px;
        margin-right: -15px;
    }
    section.gemaa-slider .gemaa-slider__caption {
        top: 60px !important;
        left: 0%;
        width: 100%;
    }
    section.floating-header.floating-header nav.navbar.navbar-default {
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
    }
    section.header-menu .header-menu__important-links li a {
        padding-left: 0 !important;
    }
    .navbar-collapse {
        float: left !important;
    }
    div#bs-slide-dropdown {
        width: 100% !important;
    }
    section.floating-header li {
        /* float: right; */
        float: left !important;
    }
    .navbar-collapse {
        margin: 0 !important;
    }
    .navbar-default .navbar-toggle {
        float: left;
        border: 0;
    }
    .navbar-default .navbar-toggle .icon-bar {
        background-color: #131313;
    }
    .navbar-toggle .icon-bar {
        display: block;
        width: 41px;
        height: 3px;
        border-radius: 1px;
        margin-bottom: 9px;
        margin-top: 7px;
    }
    button.design__btn.btn.btn-default {
        margin-top: 30px !important;
        margin-bottom: 15px !important;
    }
    section.design-your-own .carousel-inner>.item>img,
    .carousel-inner>.item>a>img {
        height: 212px !important;
        margin-bottom: 4px !important;
    }
    section.design-your-own .design-your-own__holder img {
        height: 40px;
    }
    section.design-your-own .secondry-title h2 {
        font-size: 28px !important;
        margin-bottom: 13px !important;
        line-height: 27px !important;
    }
    section.showrooms {
        padding: 40px 0 !important;
    }
    .collection__caption {
        opacity: 1 !important;
    }
    section.footer {
        padding: 20px !important;
    }
    section.collection .bottom-top {
        left: 0 !important;
    }
    .footer__title.contact-footer h4 {
        margin-top: 21px !important;
    }
    section.footer .footer__newsletter-input {
        text-align: center !important;
        width: unset !important;
    }

    section.footer .footer-icons{
        margin-top: 30px;
    }

    section.collection .bottom-right {
        left: 0 !important;
    }
    section.collection .bottom-left {
        left: 0 !important;
    }
    section.collection .right {
        left: 0 !important;
    }
    section.collection .left {
        left: 0 !important;
    }
    section.collection .top-bottom {
        left: 0 !important;
    }
    section.collection .top-right {
        left: 0 !important;
    }
    section.collection .top-left {
        left: 0 !important;
    }
    section.collection .collection__item-one {
        margin-right: 0 !important;
    }
    .footer__logo {
        margin-top: 34px;
    }
    section.footer ul.footer__links-menu {
        text-align: center !important;
    }
    section.footer .footer__title h4 {
        text-align: center !important;
        font-size: 13px !important;
    }
    section.footer hr.footer__line {
        margin: 20px 0 !important;
    }
    .collection__item {
        width: 50% !important;
    }
    section.collection .collection__item-one img,
    .collection__item-six img {
        height: 346px !important;
    }
    section.collection .collection__item-two img,
    .collection__item-three img,
    .collection__item-seven img,
    .collection__item-eight img {
        height: 166px !important;
    }
    section.collection .top-right {
        top: 65% !important;
    }
    section.collection .top-left {
        top: 28% !important;
    }
    .collection__caption h3 {
        background: #000000a3 !important;
        height: 39px !important;
        line-height: 39px !important;
        text-transform: uppercase;
    }
    section.collection .collection__caption {
        background: transparent !important;
    }
    section.collection .left {
        top: 67% !important;
    }
    section.collection .top-bottom {
        top: 0% !important;
    }
    section.collection .bottom-right {
        top: 34% !important;
    }
    section.collection .bottom-left {
        top: 34% !important;
    }
    section.collection .right {
        top: 67% !important;
    }
    section.collection .bottom-top {
        top: 68%;
    }
    section.collection .bottom-top {
        top: 68% !important;
        left: 0;
    }
    .collection__container.top-bottom {
        height: 347px;
    }
    .collection__container.top-right {
        height: 166px;
    }
    .collection__container.top-left {
        height: 166px;
    }
    .collection__container.right {
        height: 333px;
    }
    .collection__container.bottom-top {
        height: 347px;
    }
    .collection__container.bottom-right {
        height: 167px;
    }
    .collection__container.bottom-left {
        height: 167px;
    }
    .collection__container.left {
        height: 333px;
    }
    section.collection .top-right {
        top: 28% !important;
    }
    .grid-product__colors-img .thumb-img {
        float: left;
        width: 33.333333333333333333333% !important;
        margin-left: 0px !important;
        text-align: center;
        margin: 0 auto;
        display: block;
        margin-bottom: 0px;
        padding-right: 10px;
    }
    .header-mobile .navbar-nav {
        margin: 0;
        padding: 10px 0;
    }
    section.gemaa-slider .gemaa-slide__caption-holder h1 {
        font-size: 40px !important;
    }
    .header-mobile .creat-account__btn-holder p,
    .header-mobile .login-now__btn-holder p {
        color: #fff;
        margin-bottom: 5px;
    }
    .gemaa-slider .item img {
        margin: 0 auto;
        object-fit: cover;
        overflow: scroll;
        width: 100%;
    }
    a.login-now__forgot-link {
        color: #fff !important;
        margin-top: 8px;
        margin-bottom: 8px
    }
    .header-mobile button.login-now__submit-btn.btn.btn-default {
        background: white;
        color: black;
        text-transform: uppercase;
        margin-top: 10px;
    }
    a.custome {
        margin-left: 15px;
    }
    li.floating-header__icon-favicon {
        margin-left: 13px;
    }
    .header-mobile .i.fa.fa-shopping-cart {
        margin-left: 15px;
        color: #131313;
    }
    .header-mobile .navbar-default .navbar-nav>li>a {
        color: #131313;
    }
    .header-mobile .login-now__caption li a {
        color: #fff;
        font-size: 12px;
        margin: 0;
    }
    .header-mobile .login-now_holder.login-bg {
        padding: 20px 0;
    }
    .login-now__forgot-password {
        margin-bottom: 2px;
    }
    a.login-now__forgot-link {
        margin: 0 !important;
    }
    .floating-header__collapse.navbar-collapse.for-mobile li {
        float: left;
    }
    .floating-header__collapse.navbar-collapse.for-mobile li {
        color: #ffffff;
        background: #131313;
        /* padding: 7px; */
        border-radius: 50%;
        text-align: center;
        height: 35px;
        width: 35px;
        line-height: 35px;
        margin-right: 12px;
    }
    .header-mobile ul.floating-header__social-media.nav.navbar-nav li a {
        padding: 0;
        margin: 0;
    }
    .header-mobile li.floating-header__icon i {
        color: #fff;
        margin-top: 8px;
        margin-left: 1px;
    }
    .loyaltyprogram__caption {
        width: 100%;
    }
    .loyaltyprogram__holder {
        height: 300px;
        margin-bottom: 40px;
    }
}

@media (max-width:767px) {
    .slick-slider.slick-initialized {
        padding: 0px;
    }
    .collection-product__holder {
        width: 130px !important;
    }
    section.header-menu {
        position: fixed;
        width: 100%;
        z-index: 9999999;
        top: 0;
    }
    .single-product__star {
        display: block;
    }
    .app__content {
        padding-top: 100px;
    }
    .single-product__icons.for-mobile {
        display: block;
        margin: 0;
        padding-left: 0;
    }
    .company-profile_button-container .catalogue-btn {
        width: 90%;
        font-size: 12px;
        padding: 0px;
    }
    .Financial-Reports__content .catalogue-btn {
        margin: 20px 0px !important;
    }
    .catalogue-container {
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .catalogue-btn {
        width: 100%;
    }
    .company-profile-bg {
        background-size: cover;
        background-position: center;
    }
    .company-profile__content {
        width: inherit;
    }
    .company-profile td {
        border: none;
        border-bottom: none;
        position: relative;
        padding-left: 0;
    }
    .company-profile tr {
        background: transparent !important;
    }
    .company-profile-bg {
        padding: 20px;
    }
    .image-gallery-thumbnail img {
        vertical-align: middle;
        width: 100%;
        height: 20px;
        object-fit: cover;
        float: inherit;
    }
    .image-gallery-fullscreen-button::before,
    .image-gallery-play-button::before {
        font-size: 1.5em;
    }
    /* .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 75px;
        float: left;
    } */
    .image-gallery-content.fullscreen .image-gallery-image img {
        height: 90vh;
    }
    button.user-account__cancel.btn,
    button.user-account__cancel-adress.btn {
        margin-left: 0;
    }
    .slick-list li {
        margin-top: -30px;
        list-style: none;
        text-align: center;
    }
    .user-newsletter__caption {
        width: inherit;
    }
    .user-newsletter {
        overflow: hidden;
    }
    input {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 0;
        box-shadow: none;
    }
    hr.hr-login {
        height: 1px;
        background: #d9d9d947;
        display: block;
    }
    .single-product-social__btn {
        margin-right: 12px;
        margin-top: 14px;
    }
    i.single-product__cart-icon.material-icons.list-product__compare-icon {
        float: inherit;
        padding-right: 0;
        line-height: 21px;
    }
    .single-collapse,
    .single-product__details {
        width: 100%;
    }
    .single-product__icons span {
        margin-top: 5px;
    }
    .single-product__details {
        padding-left: 0;
        position: relative;
        /* top: 387px; */
        right: unset;
        top: unset;
        width: 100%;
    }
    .text-center {
        padding: 20px;
    }
    .collection-product {
        margin-bottom: 100px;
        margin-left: 15px;
        margin-right: 15px;
        border-top: 1px solid #b8b8b896;
        padding-top: 10px;
    }
    .single-product__icons {
        right: inherit;
        padding-left: 15px;
    }
    .single-product .single-modal__carousel-inner {
        width: inherit;
        margin-top: 0;
        bottom: 0;
        margin-left: 0px;
    }
    .single-product img.single-modal__thumb-photo {
        height: 40px;
        width: 60px;
        object-fit: cover;
        z-index: 999999;
    }
    .single-product .thumb {
        cursor: pointer;
        margin-right: -17px;
    }
    .collection-product__title h3 {
        margin-bottom: 18px;
        line-height: 30px;
        margin-top: 0;
        font-size: 22px;
    }
    .modal-price__holder input.single-product__btn.form-group {
        text-align: left;
    }
    .single-product__type.secondry-font {
        margin-top: 29px;
    }
    .single-product__description p.first__descripton {
        margin-bottom: 9px;
    }
    .single-product input.single-product__btn.form-group,
    .single-product select:not([multiple]) {
        width: 100%;
    }
    .shop-main__controler.control__indicator {
        height: 15px;
        width: 15px;
    }
    .control--checkbox .control__indicator:after {
        width: 11px;
        height: 11px;
        top: 1px;
        left: 1px;
    }
    .left-carousel-button {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(50%, 300%);
        display: none;
    }
    .right-carousel-button {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, 300%);
        display:none;
    }
    .gemaa-slider__caption h1 {
        margin-bottom: 10px;
    }
    section.user-account.customize input {
        width: 100%;
    }
    .gemaa-slide__centerd.col-md-12.text-center img {
        width: 394px;
        background-repeat: no-repeat;
        padding: 30px;
    }
    .user-border__right {
        margin-top: 30px;
    }
    .slick-list li:focus,
    .slick-list li:active {
        box-shadow: none;
        outline: 0 !important;
        border: 0;
    }
    .slick-dots li button {
        box-shadow: none;
        outline: none !important;
        border: 0;
    }
    .slick-slider.slick-initialized:after {
        width: 40px;
        content: "";
        position: absolute;
        height: 2px;
        background: transparent;
        width: 100%;
        left: 0;
        margin-top: 19px;
    }
    .contact-form span.call-gemma__number {
        font-size: 14px;
    }
    .grid-product-wrapper__holder.col-md-6.col-sm-6 {
        overflow: hidden;
    }
    .creat-account__btn-holder input,
    .login-now__btn-holder input {
        width: 100%;
    }
    .login-now__caption {
        margin-top: 20px;
    }
    .creat-account_holder:after {
        display: none;
    }
    .Login__caption p {
        width: inherit;
        margin-left: 0;
        margin-top: 20px;
    }
    h2.main-line-title {
        font-size: 24px;
    }
    .grid-product__icons-menu a {
        background: #999999;
        margin: 0 auto;
        display: flex;
        margin-top: 11px;
        padding-right: 0;
        margin-right: 0px;
        overflow: hidden;
    }
    .grid-product-wrapper {
        /* height: inherit; */
        height: auto !important;
    }
    .grid-product__range {
        color: #999999;
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 255);
    }
    ul.menu {
        display: none;
    }
    .contact-us__title {
        margin-bottom: 0;
    }
    h2.main-line-title {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .main-line-title {
        margin-bottom: 0;
    }
    .grid-product__icons-wrapper {
        float: inherit;
    }
    .view-selector {
        display: none;
    }
    .faq p.answer {
        width: 100%;
    }
    #distributors__eg,
    #fa_q,
    #contact_form,
    #contact__information,
    #showroom__locator,
    #distributors__eg {
        padding-top: 20px;
        margin-bottom: 20px;
    }
    .contact-information .head-quarter__wrapper {
        margin-top: 25px;
    }
    section.gemaa-slider .gemaa-slide__caption-holder {
        font-family: BaskervilleItalic;
    }
    .grid-product-wrapper__holder.col-md-6 {
        clear: both;
    }
    a.header-menu__logo.navbar-brand {
        float: right;
        padding: 0;
        margin-top: 0;
        padding: 5px 13px;
    }
    section.floating-header {
        display: none;
    }
    .bm-burger-button {
        display: block;
        z-index: 0 !important;
    }
    .Login__caption p {
        width: inherit;
        margin-left: 0;
    }
    .slick-next,
    .slick-prev {
        top: 27%;
        position: absolute;
        z-index: 9;
    }
}

@media only screen and (min-width: 767px) {
    .gemaa-slide__centerd.col-md-12.text-center img {
        width: 70%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 769px) {
    body .grid-product__icons-wrapper {
        float: inherit;
        margin-top: 10px;
    }
    .grid-product__colors-img .thumb-img {
        margin-bottom: 0;
    }
    #list__product img.thumb-img {
        float: right !important;
        width: 60% !important;
        object-fit: cover !important;
    }
    section.design-your-own .design-your-own__holder img {
        height: 80px;
    }
    .contact-information .head-quarter__wrapper {
        margin-top: 25px;
    }
}

@media (max-width: 767px) {
    p.second__descripton,
    .single-product__choose-tiles,
    .single-product__icons,
    .single-collapse a.single-collapse__btn.btn-expand-collapse,
    .single-product_quantity {
        display: none;
    }
    .carousel-inner>.item>img,
    .carousel-inner>.item>a>img {
        max-height: 400px;
        object-fit: contain;
    }
    .single-product__details {
        padding-right: 0;
    }
    input.single-product__btn.form-group {
        border: 1px solid #8080804d;
        text-indent: 10px;
        font-size: 19px;
    }
    .pagination>li {
        display: none;
    }
    button.single-product__icons-link.add-cart {
        margin-top: 10px;
    }
    .image-gallery-thumbnails-container {
        margin-top: 10px;
    }
    .single-product__type.secondry-font {
        margin-top: 10px;
    }
    .image-gallery-slide img {
        height: 120px;
    }
    .single-product__name h2 {
        margin-top: 0;
    }
    .single-product__name h2 {
        font-size: 25px;
    }
    .modal-price__holder .single-product__name.price-number p {
        margin-bottom: 10px !important;
    }
    .single-collapse {
        background: transparent;
        height: unset;
        right: unset;
        z-index: 0;
        position: relative;
    }
   
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    tr {
        margin: 0 0 1rem 0;
    }
    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }
    td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
}

@media (max-width:442px){
    .subscribe-btn{
        margin-top: 10px;
    }
}