/* Start section collection */

section.collection .collection__image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 0;
  border-radius: 0px;
  margin-bottom: 13px;
}

section.collection .collection__caption {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 101%;
  height: 100%;
  color: #fff;
  cursor: pointer;
  text-align: center;
  opacity: 1;
  font-size: 1.2em;
  border-radius: 0px;
  transition: all 0.8s ease-out;
}

section.collection .collection__image:hover .collection__caption h3 {
  background: #161616;
  transition: 0.6s;
}

section.collection .collection__holder img {
  width: 100%;
}

section.collection .collection__item-one img,
.collection__item-six img {
  height: 446px;
  object-fit: cover;
}

section.collection .collection__item-one {
  margin-right: 7px;
  overflow: hidden;
  position: relative;
}

section.collection .collection__item-two img,
.collection__item-three img,
.collection__item-seven img,
.collection__item-eight img {
  height: 216px;
  object-fit: cover;
}

section.collection .collection__item-four img,
.collection__item-five img {
  height: 333px;
  object-fit: cover;
}

section.collection .collection__caption a {
  color: #fff;
}

section.collection .collection__image:hover .collection__caption {
  opacity: 0;
}

section.collection .bottom-top {
  top: 100%;
  left: 0;
}

section.collection .bottom-right {
  top: 100%;
  left: 100%;
}

section.collection .bottom-left {
  top: 100%;
  left: -100%;
}

section.collection .right {
  top: 0;
  left: 100%;
}

section.collection .left {
  top: 0;
  left: -100%;
}

section.collection .top-bottom {
  left: 0;
}

section.collection .top-right {
  top: -100%;
  left: 100%;
}

.collection__container.top-right {
  height: 216px;
}

.collection__container.top-bottom {
  height: 446px;
}

.collection__container.top-left {
  height: 216px;
}

.collection__container.right {
  height: 345px;
}

.collection__container.bottom-top {
  height: 446px;
}

.collection__container.bottom-right {
  height: 218px;
}

.collection__container.bottom-left {
  height: 214px;
}

.collection__container.left {
  height: 345px;
}

.collection__caption {
  text-transform: uppercase;
}

.collection__image h3 {
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  margin: 0px;
  line-height: 0px;
  bottom: 0;
  width: 100%;
  position: absolute;
  cursor: pointer;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

section.collection .top-left {
  top: -100%;
  left: -100%;
}

section.collection .collection__image:hover .collection__caption {
  top: 0;
  left: 0;
  transition: all 0.13s ease-out;
}

section.collection .collection__image:hover .collection__caption {
  opacity: 1;
}

section.collection .collection__image:hover .collection__caption img {
  padding-bottom: 0 !important;
}

section.collection .collection__main-item,
.collection__item,
.collection__full-width {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 7px;
  padding-left: 0px;
}

@media (min-width: 992px) {
  .collection__item,
  .collection__main-item {
    float: left;
    width: 50%;
  }
  .collection__full-width {
    float: left;
    width: 100%;
  }
}

.collection-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

button.main-button.collection__btn.btn.btn-default:hover {
  background: #1a1a1a;
  color: #fff;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

.caption {
  overflow: hidden;
  opacity: .99;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 1.5em 0 rgba(0, 0, 0, 0.05);
  display: block;
}

.caption:before {
  content: '';
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
  width: 100%;
  height: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.caption .details {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width:100%;
}

.caption .details span {
  display: block;
  opacity: 1;
  position: relative;
  top: 50%;
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

@media only screen and (min-width:768px) {
  .caption .details span {
    opacity: 1;
    top: 100px
  }
  .caption:before {
    opacity: 1;
    top: 100%
  }
  .caption:focus:before,
  .caption:hover:before {
    top: 50%;
  }
  .caption:focus span,
  .caption:hover span {
    top: 0;
  }
  .caption:focus .title,
  .caption:hover .title {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  .caption:focus .info,
  .caption:hover .info {
    -webkit-transition-delay: 0.25s;
    transition-delay: 0.25s;
  }
}

/* End section collection */