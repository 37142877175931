


/* Start section header-menu */
section.header-menu nav.header-menu__header-nav{
border-radius:0;
padding: 18px;
background: transparent;
border:0;
}

.header-menu__navbar-header img {

}
section.header-menu nav.header-nav.navbar.navbar-default {
  padding-top: 30px;
  box-shadow: inherit;
}
section.header-menu .header-menu__important-links li a{
text-transform: uppercase;
color:#1a1a1a;
font-size: 13px;

}

section.header-menu  li.important-links__active:after {
  content: "";
  position: absolute;
  width: 71px;
  height: 3px;
  background: #8d8d8d;
  top: 40px;
  left: 3px;
}

.activeLine{
  border-bottom: 1px solid;
}
/* section.header-menu li.important-links__active {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 1px !important;
  background: #131313 !important;
  top: 40px !important;
  left: 17px !important;
} */

section.header-menu .header-menu__important-links li a{

  font-family: Montserrat-regular;

}

section.header-menu .header-menu__important-links li a {
  padding-right: 0;
  margin-left: 40px;
}


.section.header-menu  .navbar-default .navbar-nav > li > a{
  
}
/* End section header-menu */

