.warning-banner {
    position: fixed;
    width: 60%;
    margin: auto;
    z-index: 9999999;
    border: 1px solid black;
    background: white;
    top: 40px;
    left: 20%;
}

.warning-banner h4 {
    text-align: center;
    text-transform: uppercase;
    margin: 30px;
}