.forget-holder {
    padding-bottom: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: fit-content;
    margin: auto;
}

.forget-holder .login-now__btn-holder {
    margin: auto;
}

.forget-holder input.login-now__btn-password.creat__placeholder {
    margin-bottom: 18px;
}

.forget-holder button.login-now__submit-btn {
    float: right;
}