
/* Start Section.design-your-own  */

section.design-your-own {
  overflow: hidden;
}

section.design-your-own .secondry-title h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-family: Baskerville;
  line-height: 44px;
  margin-top: 0px;
}

section.design-your-own .carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  line-height: 1;
  object-fit: cover;
  height: 211px;
  width: 100%;
  margin-bottom: 13px;
}

section.design-your-own .design-your-own__holder img {
  height: 70px;
  cursor: pointer;
  object-fit: cover;

}

section.design-your-own .img-responsive {
  width: 100%
}

section.design-your-own .design-your-own__menu {
  list-style: none;
  margin-top: 10px;
  padding: 0;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

section.design-your-own .main-button button.main-button__btn {
  margin-top: 81px;
}

section.design-your-own .design-your-own__caption p {
  line-height: 22px;
  font-size: 15px;
  font-family: Montserrat-Light;

}


button.design__btn.btn.btn-default {
  border: 0;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-right: 2px solid #1e1e1e;
    border-bottom: 2px solid #1e1e1e;
    padding-right: 12px;
    font-family: Montserrat-Regular;
    font-size: 16px;
    height: 44px;
    line-height: 15px;
    font-weight: 100;
    color: #1e1e1e;
    display: inline-block;
    padding-left: 0;
    margin: 15px 0px;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
    width: 160px;
}

button.design__btn.btn.btn-default:hover {
    background: #1a1a1a;
    color: #fff;
    border-right: 2px solid #ffffff !important;
    border-bottom: 2px solid #ffffff !important;
    /* border: 0; */
  }

/* End section  section.design-your-own */
