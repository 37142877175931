/* Start section Footer */

section.footer {
  margin-top: 50px;
  padding: 130px 0;
  background: #b7b7b7;
  text-align: center;
}

button.Toastify__close-button.Toastify__close-button--default {
  color: white;
}
.Toastify__toast-container--top-right {
  top: 3em;
  right: 3em;
}

.Toastify__toast{
  text-align: center;
}

.Toastify__toast.Toastify__toast--default.sub-toast {
  background: black;
  font-family: Montserrat-Medium;
  color: white;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  width: unset;
  min-width: 320px;
}

section.footer .footer__newsletter-input {
  padding: 9px 21px 9px 21px;
  width: 350px;
}

.subscribe-btn {
  padding: 8px 20px;
  margin: 0px 5px;
  margin-top: -2px;
  height: 42px;
  border: 1px solid #CCCCCC;
  letter-spacing: 0px;
  font-size: 13px;
  border: 1px solid darkgrey;
  font-family: Montserrat-Medium;
}

section.footer .footer__newsletter h2 {
  font-family: BaskervilleItalic;
  margin-bottom: 13px;
}

section.footer hr.footer__line {
  border-top: 1px solid #c9c9c9;
  margin: 37px 0;
}

section.footer .footer__title h4 {
  text-align: left;
  font-family: Montserrat-Medium;
  text-transform: uppercase;
  font-weight: bold;
}

section.footer .footer__title h4 {
  margin-top: 0;
  margin-bottom: 13px;
}

section.footer ul.footer__links-menu {
  list-style: none;
  padding: 0;
  text-align: left;
  font-family: BaskervilleItalic;
}

section.footer ul.footer__links-menu li a {
  color: #1a1a1a;
  font-size: 19px;
  line-height: 36px;
}

section.footer li.footer__location,
section.footer li.footer__fax,
section.footer li.footer__phone {
  font-size: 18px;
}

section.footer .footer-icons{
  margin-top: 130px;
}

button.main-button.collection__btn.btn.btn-default.subscribe-btn:hover {
  border: 1px solid #1a1a1a;
}



/* End section Footer */