.About img {
    width: 100%;
}


.About__content p {
    color: #000000;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
margin-bottom: 45px;
    font-family: Montserrat-light;
}

.About__title-compontent h3 {
    font-family: BaskervilleItalic;
    font-size: 40px;
    color: rgba(0, 0, 0, 255);
   text-align: center;
    margin-top: 0;
}

.About__bg {
    background: #999999;
    padding: 90px 0;
    color: #fff;
    font-size: 16px;
    font-family: Montserrat-light;
}

.About__caption-holder {
    padding: 90px 0;
    font-size: 16px;
    font-family: Montserrat-light;
}
.counter__title h3 {
    text-align: center;
    font-size: 36px;
    font-family: Baskerville;
    margin-bottom: 30px;
    color: #999999;

}
.counter {
    padding: 60px 0;
    padding-bottom: 120px;
    
}

.count-number span {
    font-size: 100px;
    font-family: Montserrat-Bold;
}

.single_counter h3 {
    color: #999999;
    margin-bottom: 10px;
    font-family: BaskervilleItalic;
    font-size: 24px;
}
.single_counter p {
    font-family: Montserrat-ExtraLight;
    margin: 0;
    letter-spacing: 3px;

    margin: 0;
}

.about-banner{
    background-image: url("../../img/About/gemma_about-bg1.jpg");
    padding: 80px 0;
    background-size:cover;
    position: relative;

}

.about-banner h4{
    font-size: 36px;
color:#fff;
font-family: BaskervilleItalic;

line-height: 43px;

}

.About__text-compontent p {
    line-height: 26px;
}

#about-banner .overlay {
    background-color: #999999b8;
    width: 100%;
    height: 100%;
    
    position: absolute;
    left: 0;
    top: 0;
}
