.user-account-information input,
.user-accountــaddress input {
    padding: 7px 11px;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 40px;
}

div#user-point {
    min-height: 340px;
}

.user-account-information p,
.user-accountــaddress p {
    font-family: Montserrat-Light;
    color: rgba(0, 0, 0, 255);
}

.user-accountــaddress h3 {
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    color: rgba(0, 0, 0, 255);
    margin-bottom: 25px;
}

.user-account-information ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    color: rgba(179, 179, 179, 255);
}

.user-account-information ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(179, 179, 179, 255);
    font-size: 14px;
}

.user-account-information :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(179, 179, 179, 255);
    font-size: 14px;
}

.loyalty-drop {
    width: 200px;
    height: 200px;
    border: 2px solid;
    margin-top: 20px;
    padding: 10px;
    cursor: alias;
}

.user-account-information :-moz-placeholder {
    color: rgba(179, 179, 179, 255);
    font-size: 14px;
}

button.user-account__cancel.btn,
button.user-account__cancel-adress.btn {
    background: #b7b7b7;
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: 14px;
    height: 40px;
    width: 78px;
    margin-right: 11px;
    font-family: Montserrat-Light;
    margin-left: 50px;
}

button.user-account__save.btn,
button.user-account__save-address.btn {
    background: #000000;
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: 14px;
    height: 40px;
    width: 98px;
    margin-right: 0;
    font-family: Montserrat-Light;
    margin-left: 3px;
}
.user-point .sk-spinner {
    margin: 30px;
    display: block;
}
button.user-account__save-address.btn {
    width: 118px;
}

button.user-account__cancel-adress.btn {
    margin-left: 28px;
}

.user-account.menu li a.active {
    color: #000000;
    font-weight: bold;
}

.user-account__sidebar a {
    color: #999999;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
}

.user-account-information {
    margin-bottom: 60px;
}

.user-wishlist__sort {
    background: #999999;
    height: 65px;
}

.first h4 {
    font-family: BaskervilleItalic;
    font-size: 21px;
    color: rgba(255, 255, 255, 255);
    text-align: left;
    margin-left: 52px;
    text-transform: capitalize;
}

.first {
    width: 40%;
    /* background: red; */
    float: left;
    padding: 10px;
}


.user-wishlist__name h4 {
    text-transform: uppercase;
    margin-top: 26px;
    font-family: Montserrat-Medium;
    font-size: 24px;
    color: rgba(0, 0, 0, 255);
    /* text-align: left; */
}

.user-wishlist__icons-wrapper {
    margin-top: 20px;
}

.user-wishlist__state-holder h3 {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 23px;
}

.user-wishlist__range-holder p {
    margin: 0;
}

.user-wishlist__range p {
    font-size: 30px;
}

.user-wishlist__range-currency p {
    font-size: 20px;
    margin-top: -19px;
}

.list-produc__id-holder p {
    font-size: 17px;
    font-weight: bold;
}

.user-wishlist__range-price p {
    font-size: 48px;
    margin-top: -25px;
    margin-bottom: 2px;
}

.user-wishlist__colors-img {
    margin-top: 15px;
}

.user-wishlist__icons-menu {
    margin-top: 18px;
    margin-bottom: 18px;
}

.user-wishlist__icons-menu a {
    background: #999999;
    padding: 10px 18px;
    color: #fff;
    float: left;
    margin-bottom: 16px;
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    text-align: center;
}

.user-wishlist__data-add p {
    margin-top: 18px;
    margin-bottom: 18px;
    color: #999999;
}

.user-wishlist__remove {
    margin-top: 116px;
    color: #999999;
}

.user-wishlist i.user-wishlist__cart-icon.fa.fa-shopping-cart {
    margin-right: 5px;
    float: left;
}

.user-wishlist__icons span {
    font-family: Montserrat-Medium;
    margin-left: 4px;
}

.user-wishlist__id-holder p {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
}

.user-wishlist__id-holder span.user-wishlist__id {
    text-transform: uppercase;
}

.user-wishlist .thumb-img {
    cursor: pointer;
    width: 100px;
    height: 50px;
    object-fit: cover;
}

.user-wishlist i.user-wishlist__fav-icon.fa.fa-star:hover,
focus {
    color: #fcaf17;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
    transition: all 0.5s ease;
    -webkit-transform: rotate(360deg);
    -webkit-transition-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

.list-modal .carousel-inner {
    position: absolute;
}

.carousel-control.right,
.modal .carousel-control.left {
    background-image: none
}

.list-modal__left-arrow {
    margin-right: 50px;
    margin-top: 20px;
}

.list-modal__right-arrow {
    margin-top: 20px;
    padding-left: 15px;
}

.user-wishlist__sort-menu {
    width: 20%;
    float: left;
}

.user-wishlist__sort-menu.status .dropdown {
    margin-left: 40px;
}

ul.user-wishlist__sort-menu.dropdown-menu {
    background: black;
    border-radius: 0;
    margin-top: 11px;
    padding: 12px 0;
}

ul.user-wishlist__sort-menu.dropdown-menu a {
    color: #fff;
}

ul.user-wishlist__sort-menu.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    background: transparent;
}

.user-wishlist button.user-wishlist__sort-btn.btn-primary.dropdown-toggle {
    background: transparent;
    border: 0;
    margin-top: 21px;
    font-family: Montserrat-Light, MyriadPro-Regular, AdobeInvisFont;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    text-align: center;
}

.control--radio .control__indicator {
    border-radius: 0;
    background: white;
    border: 1px solid #cccccc;
}

.control--radio .control__indicator:after {
    left: 3px;
    top: 3px;
    height: 12px;
    width: 12px;
    border-radius: 0;
    background: #cccc;
}

label.interested-in__name.control.control--radio {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
    font-weight: 300;
}

.interested-in.control-group p {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
    font-weight: 300;
    margin-bottom: 23px !important;
}

.user-newsletter__caption p {
    font-family: Montserrat-Light, MyriadPro-Regular, AdobeInvisFont;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
}

.user-newsletter .control-group {
    margin: 0;
}

.user-newsletter {
    margin-bottom: 60px;
}

.user-wishlist__btn-holder .btn {
    border-radius: 0;
    color: #fff;
    font-family: Montserrat-Light;
}

button.user-wishlist__cancel-btn.btn.btn-default {
    color: #fff;
    background: #b7b7b7;
}

button.user-wishlist__cancel-btn.btn.btn-default {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    text-align: center;
    width: 78px;
    height: 40px;
}

button.user-wishlist__save-btn.btn.btn-default {
    height: 40px;
    background: #000;
    margin-left: 10px;
}

.user-wishlist__btn-holder {
    margin-top: 15px;
}

.user-point h3 {
    font-family: Montserrat-Medium;
    font-size: 36px;
    color: rgba(0, 0, 0, 255);
}

.user-point p {
    font-family: Montserrat-Light;
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
}

.user-point__caption p {
    margin-bottom: 0;
}

.user-point__caption h3 {
    margin-top: 14px;
    margin-bottom: 25px;
}

.user-point__code h3 {
    margin-bottom: 29px;
    margin-top: 13px;
}

button.user-point__info-btn.btn.btn-default {
    border-radius: 0;
    font-family: Montserrat-Light, MyriadPro-Regular, AdobeInvisFont;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    text-align: center;
    height: 40px;
    width: 229px;
    background: #000000;
}

button.user-wihlist__cancel-btn.btn:hover,
button.user-account__cancel-adress.btn.btn-default:hover,
button.user-account__cancel-adress.btn :hover,
button.user-account__cancel.btn.btn-default:hover {
    background: white;
    color: #b7b7b7;
    border: 1px solid #b7b7b7;
    transition: all 0.2s ease;
}

.menu li a.active {
    color: #000000;
    font-weight: bold;
}

.user-border__right:before {
    position: absolute;
    border-left: 2px solid #9f9f9f;
    left: -23px;
    content: "";
    height: 100%;
    top: 0;
    padding-right: 15px;
}

hr.user-account__hr {
    border: 1px solid #e8e8e8;
    margin-bottom: 20px;
    margin-top: 20px;
}

.user-account__sidebar {
    position: relative;
}

#user-address {
    margin-bottom: 60px;
}