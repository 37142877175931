.Financial-Reports__content .catalogue-btn {
    margin: 20px;
    font-size: 25px;
    background: white;
}

.FinancialReports {
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-bottom: -50px;
}

.catalogues-bg {
    height: 100vh;
    display: flex;
    justify-content: center;
}