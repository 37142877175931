.investor__btn button {
    width: 100%;
    height: 100%;
    font-size: 15px;
    letter-spacing: 0px;
}

.investor__btn {
    width: 10;
    margin-bottom: 15px;
    margin-left: -15px;
}