.line-scale > div {
    width: 2px !important;
    height: 100px !important;
    margin: 0px 10px !important;
}

.sk-spinner {
    margin: 200px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}