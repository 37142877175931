section.projects label.btn.btn-default {
    width: 100%;
    margin: 20px 0px;
    border-radius: 0px !important;
}

section.projects .grid-product-wrapper__holder.col-md-6{
    margin-top: 0px;
}

section.projects .shop-main__shop-layered {
    padding-bottom: 20px;
}

section.projects section.shop-selector {
    margin-bottom: 30px;
}