 .single-collapse {
     bottom: 0px;
     right: 40px;
     position: absolute;
     top: 15px;
     z-index: 999;
     overflow: hidden;
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     transition: all 0.5s ease-in-out;
     width: 490px;
     height: 1050px;
     background: #f3f3f3b8;
     overflow-y: scroll;
 }
 .single-collapse.collapsed {
     width: 70px;
 }
 .single-product__star{
     display: none;
 }
 /* .single-product__facebook-btn:hover, a:focus{
   background: transparent !important;
} */

 .single-collapse.collapsed .glyphicon {
     font-size: 22px;
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     transition: all 0.5s ease-in-out;
 }

 .single-collapse .glyphicon {
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     transition: all 0.5s ease-in-out;
 }

 .single-collapse.collapsed .nav-label {
     display: none;
 }

 .btn-expand-collapse {
     position: absolute;
     display: block;
     right: 0px;
     top: 71px;
     width: 20px;
     padding: 8px 0;
     color: black;
     font-size: 32px;
     text-align: right;
     z-index: 9999999;
     right: 27px;
     height: 32px;
 }

 .btn-expand-collapse:active {}

 .single-collapse-menu,
 .single-collapse-menu li {
     margin: 0;
     padding: 0;
     list-style: none;
 }

 .single-collapse-menu li a {
     display: block;
     padding: 10px 18px;
     text-align: left;
     color: #ccc;
 }

 .single-collapse-menu li a:hover {
     background-color: #000;
     text-decoration: none;
     color: white;
 }

 .single-collapse-menu li a .glyphicon {
     margin-right: 6px;
 }

 .single-collapse-menu li a:hover .glyphicon {
     color: orchid;
 }

 /* .collapsed {
   height: 85%;
}

 */

 .single-product .item .thumb img {
     margin: 2px;
     opacity: 0.7;
 }

 .single-product .thumb {
     cursor: pointer;
     float: left;
     margin: 2px;
     margin-right: 20px;
 }

 .single-product .carousel-inner>.item>img,
 .carousel-inner>.item>a>img {
     width: 100%;
 }

 .single-product .carousel-inner {
     position: absolute;
 }

 .single-product img.single-modal__thumb-photo {
     height: 80px;
     width: 100px;
 }

 .single-product img.single-modal__thumb-photo {
     /* margin: 2px; */
     margin-top: 18px;
     margin-left: 18px;
 }

 .single-product__range-holder {
     color: #999999;
     text-align: left;
 }

 .single-product__type {
     text-align: left;
 }

 .single-product__name h2 {
     text-align: left;
     margin-top: 30px;
     margin-bottom: 15px;
 }

 .single-product__range p {
     font-size: 30px;
 }

 .single-product__range p {
     font-size: 30px;
 }

 .single-product__range-holder p {
     margin: 0;
 }

 .single-product__range-currency p {
     font-size: 20px;
     margin-bottom: 20px;
     line-height: 18px;
 }

 .single-product__range-currency.secondry-font.currency-custom p {
     line-height: 15px;
 }

 .single-product__range-price p {
     font-size: 48px;
     margin-top: 20px;
     /* padding: 0; */
     line-height: 21px;
 }

 .single-product__range-price.secondry-font.single-custom2 p {
     line-height: 32px;
     margin-bottom: 10px;
 }

 .single-product__range-price.secondry-font.single-custom p {
     line-height: 34px;
     margin-bottom: 6px;
 }

 .single-product input.single-product__btn.form-group {
     height: 36px;
     width: 56px;
     text-align: center;
     float: left;
     padding-left: 11px;
 }

 .single-product_holder.col-md-3.col-xs-4.no-padding {
     width: 65px;
     margin-right: 15px;
 }

 .single-product .form-control {
     height: 34px;
     width: 27px;
     box-shadow: inherit !important;
     border-radius: inherit !important;
     border-radius: 0 !important;
 }

 .single-product select:not([multiple]) {
     -webkit-appearance: none;
     -moz-appearance: none;
     background-position: center 50%;
     background-repeat: no-repeat;
     /* padding: .5em; */
     border-radius: 0;
     padding-right: 24px;
     text-align: center;
     /* padding: 19px; */
     padding-bottom: 2px;
     border: 1px solid #cccccc;
     text-align: center;
     padding-left: 14px;
     padding-left: 21px;
     padding-bottom: 6px;
     padding-top: 6px;
     background: #fff;
     height: 37px;
 }

 .single-product__description p,
 .single-product__choose-tiles p {
     color: #000;
     font-size: 14px;
     text-align: left;
 }

 .single-product__id {
     color: #000;
     font-weight: bold;
     font-size: 15px;
     text-align: left;
     font-family: Montserrat-Light;
     font-size: 14px;
     color: rgba(0, 0, 0, 255);
     text-align: left;
     margin: 0;
 }

 .single-product__type.secondry-font h3 {
     font-family: BaskervilleItalic;
     font-size: 14pt;
     color: rgba(153, 153, 153, 255);
     text-align: center;
     margin-bottom: 4px;
     margin-left: 15px;
 }

 .single-product__name h3 {
     margin: 0;
     font-size: 14px;
     margin-top: 17px;
     line-height: 23px;
 }

 .single-product__icons-menu a {
     background: #000000;
     padding: 0px 0;
     margin-right: 0px;
     color: #fff;
     float: left;
     margin-top: 0px;
     height: 40px;
 }

 .single-product__icons-menu a i {
     font-size: 25px;
     margin-left: 8px;
     margin-top: 8px;
 }

 .single-product__name {
     text-align: left;
     text-transform: uppercase;
 }

 .single-product__name h2 {
     font-size: 36px;
 }

 .single-product a.single-product__icons-link {
     padding-bottom: 11px;
 }

 .single-product a.single-product__icons-link:focus {
     color: #000000;
     background: black !important;
     text-decoration: none;
     -moz-transition: transform 1s;
     -webkit-transition: transform 1s;
     transition: transform 1s;
     transition: all 0.5s ease;
     -webkit-transition-duration: 1s;
     -webkit-animation-timing-function: linear;
     -webkit-animation-iteration-count: infinite;
 }

 .single-product i.single-product__cart-icon.fa.fa-shopping-cart {
     margin-right: 5px;
     float: left;
 }

 .single-product .single-modal__carousel-inner {
     position: absolute;
     bottom: 105px;
     margin-left: 16px;
     width: 100%;
 }

 .single-product .carousel-inner {
     position: relative;
 }

 /* .single-product__details-holder {
   width: 310px;
} */

 a.single-product__icons-link.my-favicon {
     width: 40px;
     height: 40px;
     margin-right: 10px;
 }

 .single-product__details {
     position: absolute;
     right: 0;
     top: 17px;
     padding-right: 70px;
     padding-left: 24px;
     width: 458px;
     padding-bottom: 12px;
 }

 .single-product__icons span {
     font-size: 18px;
     font-weight: bold;
     margin-left: 0;
     float: right;
     margin-top: 0;
     margin-right: 0;
     font-family: Montserrat-light;
     font-size: 12pt;
     height: 40px;
     margin-top: 8px;
     margin-right: 2px;
     line-height: 10px;
     margin-right: 5px;
 }

 .single-product__description p.first__descripton {
     margin-bottom: 25px;
     line-height: 23px;
 }

 p.second__descripton {
     line-height: 23px;
 }

 .single-product__description p.second__descripton {
     margin-bottom: 28px;
 }

 .single-product__choose-tiles p {
     margin-bottom: 14px;
 }

 .single-product__type.secondry-font h3 {
     margin: 0;
     margin-left: 13px;
 }

 @import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
 #social {
     margin: 20px 10px;
     text-align: center;
 }

 .single-product-social__btn {
     display: inline-block;
     position: relative;
     cursor: pointer;
     width: 38px;
     height: 38px;
     /* box-shadow: 0 2px 2px #999; */
     padding: 0px;
     text-decoration: none;
     text-align: center;
     color: #fff;
     font-size: 25px;
     font-weight: normal;
     line-height: 38px;
     border-radius: 25px;
     -moz-border-radius: 25px;
     -webkit-border-radius: 25px;
     float: left;
     margin-right: 17px;
     margin-top: 25px;
 }

 /* facebook button class*/

 .single-product__facebook-btn:hover,
 .single-product__pinterest-btn:hover,
 .single-product__instagram-btn:hover,
 .single-product__mail-btn:hover,
 .single-product__twitter-btn:hover {
     -moz-transition: transform 1s;
     -webkit-transition: transform 1s;
     transition: transform 1s;
     transition: all 0.5s ease;
     -webkit-transform: rotate(360deg);
     -webkit-transition-duration: 1s;
     -webkit-animation-timing-function: linear;
     -webkit-animation-iteration-count: infinite;
 }

 .single-product__facebook-btn,
 .single-product__twitter-btn {
     -moz-transition: transform 1s;
     -webkit-transition: transform 1s;
     transition: transform 1s;
 }

 .single-product__facebook-btn {
     background: #1a1a1a;
 }

 .single-product__image-holder {
     height: 200px;
     display: flex;
     padding: 10px;
     cursor: pointer;
 }

 .single-product__image:hover>img {
     transform: scale(1.05)
 }

 .single-product__image {
     display: flex;
     padding: 10px;
     background: transparent;
     border: 1px solid grey;
     -webkit-box-shadow: 5px 1px 14px 0px rgba(0, 0, 0, 0.3);
     box-shadow: 5px 1px 14px 0px rgba(0, 0, 0, 0.3);
 }

 .single-product__image img {
     transition: transform .3s;
     width: 100%;
     object-fit: contain;
 }

 .single-product__facebook-btn:before {
     /* use :before to add the relevant icons */
     font-family: "FontAwesome";
     content: "\f09a";
     /* add facebook icon */
 }

 .single-product__facebook-btn:hover,
 focus {
     color: #4060A5;
     background: #fff;
 }

 /* .single-product__facebook-btn:hover, a:focus{
   color: #4060A5;
   background: #fff;
   text-decoration: none;
   
} */

 /* twitter button class*/

 .single-product__twitter-btn {
     background: #1a1a1a;
 }

 .single-product__twitter-btn:before {
     font-family: "FontAwesome";
     content: "\f099";
     /* add twitter icon */
 }

 .single-product__twitter-btn:hover {
     color: #00ABE3;
     background: #fff;
 }

 /* pinterest button class*/

 .single-product__pinterest-btn {
     background: #1a1a1a;
 }

 .single-product__pinterest-btn:before {
     font-family: "FontAwesome";
     content: "\f0d2";
     /* add pinterest icon */
 }

 .single-product__pinterest-btn:hover {
     color: #cb2027;
     background: #fff;
 }

 .single-product__instagram-btn {
     background: #1a1a1a;
 }

 .single-product_quantity .form-group {
     margin-bottom: 14px !important;
 }

 .single-product__instagram-btn:before {
     font-family: "FontAwesome";
     content: "\f16d";
     /* add pinterest icon */
 }

 .single-product__instagram-btn:hover {
     color: #cb2027;
     background: #fff;
 }

 .single-product__mail-btn {
     background: #1a1a1a;
 }

 .single-product__mail-btn:before {
     font-family: "FontAwesome";
     content: "\f0e0";
     /* add pinterest icon */
 }

 .single-product__mail-btn:hover {
     color: #5b3e24;
     background: #fff;
 }

 i.single-product__fav-icon.fa.fa-star:hover,
 focus {
     color: #fcaf17;
     -moz-transition: transform 1s;
     -webkit-transition: transform 1s;
     transition: transform 1s;
     transition: all 0.5s ease;
     -webkit-transform: rotate(360deg);
     -webkit-transition-duration: 1s;
     -webkit-animation-timing-function: linear;
     -webkit-animation-iteration-count: infinite;
 }

 span.glyphicon-menu-right.glyphicon,
 span.glyphicon-menu-left.glyphicon {
     -moz-transition: transform 1s;
     -webkit-transition: transform 1s;
     transition: transform 1s;
     color: #000;
 }

 span.glyphicon-menu-right.glyphicon {
     transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -webkit-transform: rotate(180deg);
     color: #000;
 }

 span.glyphicon-menu-left.glyphicon {
     transform: rotate(-180deg);
     -moz-transform: rotate(-180deg);
     -webkit-transform: rotate(-180deg);
     color: #000;
 }

 span.single-product__calc {
     text-decoration: underline;
 }

 hr.single-product__hr {
     border: 1px solid #999999;
 }

 .single-product__name.price-number h3 {
     margin-top: 0;
     background: #ffffff;
     /* padding: 4px 0; */
     height: 36px;
     /* width: 47px; */
     border: 1px solid #cccccc;
     text-align: center;
     line-height: 36px;
     font-family: Montserrat-light;
 }

 .single-product__name.price-number p {
     text-transform: lowercase;
     font-family: BaskervilleItalic;
     font-size: 14pt;
     color: rgba(153, 153, 153, 255);
     text-align: center;
     margin-bottom: 0;
     margin-top: -6px;
 }

 a.single-product__icons-link.add-cart:focus,
 a.single-product__icons-link.add-cart:active,
 a.single-product__icons-link.add-cart:hover {
     background: white !important;
     color: #000;
     font-weight: normal;
     transition: 0.3s;
     outline: 0;
 }

 .single-product__icons-wrapper {
     margin-bottom: 15px;
 }

 .single-product .item .thumb img:hover {
     opacity: 1;
     transition: 0.3s;
 }

 .single-product {
     overflow: hidden;
 }

 .modal-header h5 {
     margin-top: 10px;
     color: #8e8b8b;
     font-size: 17px;
     font-family: Montserrat-Light;
 }

 .single-product__image-holder .caption {
     overflow: hidden;
     opacity: .99;
     width: 100%;
     cursor: pointer;
     position: relative;
     box-shadow: 5px 1px 14px 0px rgba(0, 0, 0, 0.3);
     display: flex;
 }

 .caption:before {
     content: '';
     background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
     background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.85) 100%);
     width: 100%;
     height: 50%;
     opacity: 1;
     position: absolute;
     top: 50%;
     left: 0;
     z-index: 2;
     -webkit-transition-property: top, opacity;
     transition-property: top, opacity;
     -webkit-transition-duration: 0.3s;
     transition-duration: 0.3s;
 }

 .caption .details {
     position: absolute;
     bottom: 0;
     left: 0;
     z-index: 3;
     width: 100%;
 }

 .caption .details span {
     display: block;
     opacity: 1;
     position: relative;
     top: 50%;
     -webkit-transition-property: top, opacity;
     transition-property: top, opacity;
     -webkit-transition-duration: 0.3s;
     transition-duration: 0.3s;
     -webkit-transition-delay: 0s;
     transition-delay: 0s;
 }

 .single-product_quantity {
     overflow-y: scroll;
     overflow-x: hidden;
     height: 670px;
}

.single-collapse.collapsed .single-product__icons {
    display: none;
}

 @media only screen and (min-width:768px) {
     .caption .details span {
         opacity: 1;
         top: 100px
     }
     .caption:before {
         opacity: 1;
         top: 100%
     }
     .caption:focus:before,
     .caption:hover:before {
         top: 50%;
     }
     .caption:focus span,
     .caption:hover span {
         top: 0;
     }
     .caption:focus .title,
     .caption:hover .title {
         -webkit-transition-delay: 0.15s;
         transition-delay: 0.15s;
     }
     .caption:focus .info,
     .caption:hover .info {
         -webkit-transition-delay: 0.25s;
         transition-delay: 0.25s;
     }
 }