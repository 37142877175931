.grid-product-wrapper {
    /* box-shadow: 5px 1px 14px 0px rgba(0, 0, 0, 0.3); */
    padding: 0;
    border: 2px solid #b3b3b3;
    height: 410px;
}
.grid-product__name h4 {
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-top: 10px;
    font-family: Montserrat-Medium;
    color: #131313;
    font-size: 20px;
    cursor: pointer;
}

.grid-product__main-photo img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.grid-product-custome.col-md-9 {
    padding: 0px;
}
.grid-product__range {
    color: #999999;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 255);
}

.grid-product__range-holder p {
    margin: 0;
    font-family: Montserrat-ExtraLight;
}
.grid-product__range p {
    color: #000;
    font-size: 14px;
    line-height: 25px;
}

.grid-product__id-holder p {
    font-family: Montserrat-ExtraLight;
    font-size: 15px;
    color: #000000;
    margin-top: 7px;
    margin-bottom: 0px;
}
.grid-product__range-currency {

}

.grid-product__range-currency p {

}

.grid-product__range-price p {
font-size: 18px;
line-height: 18px;
  font-family:Montserrat-Medium;

}

.grid-product hr.grid-product__hr {
    border-top: 2px solid #e6e6e6;
    margin-top: 10px;
    margin-bottom: 0px;
}
.grid-product__colors-img {
    margin-bottom: 11px;
}

.grid-product__icons-wrapper {
    float: right;
    margin-top: 10px;
    /* display: none; */
}

.grid-producr__id-holder h4 {
    margin-bottom: 25px;
    font-weight: bold;
}

.grid-product__details.col-md-9 {
    padding-left: 10px;
}

.grid-product__icons-menu a {
    background: #999999;
    display: block;
    margin-bottom: 10px;
    margin-right: 18px;
    color: #fff;
    width:30px;
    height: 30px;
    margin-top: 10px;
}
.grid__product__padding.col-md-3 {
    padding-left: 0px;
    padding-right: 0px;
}

.grid-product-wrapper__holder.col-md-6 {
    margin-top: 18px;
    margin-bottom: 18px;
}

.grid-product__icons-menu a i {
    font-size: 22px;
    cursor: pointer;
    overflow: hidden;
    margin: auto;
    outline: 0;
}
.grid-product i.grid-product__fav-icon.fa.fa-star:hover,
focus {
    color: #fcaf17;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
    transition: all 0.5s ease;
    -webkit-transform: rotate(360deg);
    -webkit-transition-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

.grid-product i.grid-product__compare-icon.fa.fa-plus {
    margin-left: 4px;
}

.grid-product__icons-menu a {
    background: #999999;
}

.grid-product__id-holder {

}

.grid-product__id-holder h4 {
    font-weight: bold;
}

.grid-product__id-holder span.grid-product__id {
    text-transform: uppercase;
}

.grid-product__colors-img .thumb-img {
    cursor: pointer;
    width: 100%;
    object-fit: cover;
    padding-right: 10px;
    height: 76px;
    margin-left: 10px;
    padding-left: 0;
    display: inline-block;
}
.grid-product__colors-img .thumb-img-no-padding-right {
    cursor: pointer;
    width: 100%;
    object-fit: cover;
    padding-right: 10px;
    height: 76px;
    margin-left: 10px;
    padding-left: 0;
    display: inline-block;
}
@media  (max-width:769px) {
    .grid-product__colors-img .thumb-img-no-padding-right {
        float: left;
    width: 33.333333333333333333333%;
    margin-left: 0px !important;
    text-align: center;
    margin: 0 auto;
    display: block;
    padding-right: 15px;
    margin-bottom: 0px;
    }
}
.grid-modal .modal-body {
    padding: 0;
}

.grid-modal .modal-header {
    border: none;
    padding: 0;
}

.modal-content {
    -webkit-box-shadow: inherit;
    border: inherit;
    box-shadow: inherit;
}


/* CSS used here will be applied after bootstrap.css */

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    height: 500px;
    object-fit: cover;
    width: 100%;
}

.thumb {
    width: 88px;
    cursor: pointer;
    float: left;
    margin: 2px;
}

.grid-modal img.grid-modal__thumb-photo {
    width: 100%;
    /* margin: 2px; */
    object-fit: cover;
    height: 60px;
    margin-top: 18px;
    margin-left: 18px;
    padding-right: 10px;
}

.grid-modal img.grid-modal__thumb-photo {
    width: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0 !important;
    opacity: 1;
    z-index: 9999999;
    left: inherit;
}

.grid-product__main-photo img:hover {
    cursor: pointer;
}

.grid-modal__close {
    margin-bottom: 10px;
    opacity: 1;
}

.grid-modal .carousel-inner {
    position: absolute;
}

.carousel-control.right,
.modal .carousel-control.left {
    background-image: none
}

.grid-modal__left-arrow {
    margin-right: 50px;
    margin-top: 20px;
}

.grid-modal__right-arrow {
    margin-top: 20px;
    padding-left: 15px;
}



div#grid__product {
    overflow: hidden;
    margin-bottom: 40px;

}

div#list__product{
    margin-bottom: 40px;

}
.list-product .ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    margin-left:0px;
}

.ribbon {
    position: absolute;
    left: -6px;
    top: 7px;
    /* z-index: 1; */
    margin-left: 11px;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;

    background: linear-gradient(#313131 0%, #000000 100%);
    -webkit-box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
    background-size: 100%;
  
}
.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #000000;
    border-top: 3px solid #000000;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #000000;
}
.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-top: 3px solid #000000;
    border-top: 3px solid #000000;
}
  .box {
    position: relative;
  }





















@-webkit-keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}
@keyframes flow {
	0% { left:-20px;opacity: 0;}
	50% {left:100px;opacity: 0.3;}
    100%{ left:180px;opacity: 0;}
}

.glow{ background: rgb(255,255,255); width:40px; height:100%; z-index:999; position:absolute;-webkit-animation: flow 1.5s linear infinite;-moz-animation: flow 1.5s linear infinite;-webkit-transform: skew(20deg);
	   -moz-transform: skew(20deg);
	     -o-transform: skew(20deg);background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* IE10+ */
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */ border-left:1px solid #fff;}


a.grid-product__icons-link {
    margin-top: 0px;
    margin-right: 0px;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}
.grid-product__info {
    padding: 0px;
}
i.grid-product__fav-icon.fa.fa-picture-o {
    font-size: 20px;
    text-align: center;
    justify-content: center;
    margin: auto;
}
.grid-product-wrapper.box {
    padding: 10px;
    height: 410px;
    margin-right: 14px;
    box-shadow: 5px 1px 14px 0px rgba(0, 0, 0, 0.3);
}

i.list-product__fav-icon.fa.fa-picture-o {
    font-size: 20px;
    /* margin: 0 auto; */
    /* display: block; */
    /* line-height: 30px; */
    margin: m;
    text-align: center;
    text-indent: -4px;
    line-height: 26px;
}
.grid-product__icons-menu a:hover,.list-product__icons-menu a:hover {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background: #1a1a1a;
    color: #ffffff;
}