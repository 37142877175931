.shop-pagination {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.shop-pagination .pagination{
    width: 100%;
    display: flex;
    justify-content: center;
}

.shop-pagination li.active span:hover {
    background: transparent !important;
    color: #000;
}

.shop-pagination li span{
    background: transparent;
    border: 0;
    /* color: red; */
    color: black;
    float: left;
    padding: 8px 12px;
    margin-right: 25px;
    text-decoration: none;
    transition: background-color .3s;
    margin: 0 2px 2px 0px;
    /* font-weight: bold; */
    font-size: 14px;
    font-family: Montserrat-Light;

}

.pagination > li > a:hover, .pagination > li > span:hover{
    background: #e2e2e2;
    border-radius: 0;
    transition: all 0.5s ease;
}

li.shop-pagination__link-previous a:hover ,li.shop-pagination__link-next a:hover {
    background: transparent !important;
    border: 0;
    border-radius: 0;
    color: #000;
    transition: all 0.5s ease;
    font-family: Montserrat-Medium; 


}

li.shop-pagination__link-previous a:focus ,li.shop-pagination__link-next a:focus {
    background: transparent !important;
    border: 0;
    border-radius: 0;
    color: #000;
    font-family: Montserrat-Medium; 

    transition: all 0.5s ease;

}


a.shop-pagination__link:hover {
    background: #e2e2e2;
    border-radius: 0;
    transition: all 0.5s ease;
}

li.shop-pagination__link-previous a {
    margin-right: 40px;
    font-size: 16px;
    font-family: Montserrat-Medium;
    color:#000;
}
li.shop-pagination__link-next a {
    margin-left: 40px;
    font-size: 16px;
    font-family: Montserrat-Medium;
    color:#000;
    /* font-weight: bold; */
}
.shop-pagination li a {
    border: 0;
}

a.shop-pagination__link-next:focus ,a.shop-pagination__link-previous:focus {
      background: transparent;
    border-radius: 0;
    color: #fff;
    transition: all 0.6s ease;

    /* font-weight: bold; */
}

.shop-pagination li.active span {
    background: transparent;
    border: 0;
    border-bottom: 2px solid black;
    color: black;
    float: left;
    padding: 8px 12px;
    margin-right: 25px;
    text-decoration: none;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    margin: 0 2px 2px 0px;
    font-size: 14px;
    font-family: Montserrat-Medium; 
}


.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    background: #e2e2e2;
    border-radius: 0;
    transition: all 0.5s ease;
    color:black;
}

.pagination > li > a, .pagination > li > span{
    padding: 8px 12px !important;
    color: #000;

}

