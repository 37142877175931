.design-img img {
    width: 100%;
    margin-bottom: 45px;
}

.design-your-own__wrapper.row {
    margin-bottom: 45px;
}

.navbar-default .navbar-brand {
    color: #777;
    padding: 21px 30px;
}
.navbar-default .navbar-nav > li > a {
    color: #777;
    padding-left: 0;
}


section.header-menu nav.header-menu__header-nav {
    padding: 15px;
    background: transparent;
    border: 0;
    position: absolute;
    z-index: 1;
    background: #ffffffba;
    width: 100%;
    padding-bottom: 10px;
  }

  section.header-menu .header-menu__important-links li a {
    padding-right: 0;
    margin-top: 21px;
    padding-bottom: 5px;
}
section.collection {
    margin-right: -15px;
}
.design-img {
    margin-bottom: 45px;
}

.catalogue-container {
    display: flex;
    justify-content: center;
}

.catalogue-btn {
    border: 0px;
    font-size: 20px;
    padding: 16px;
    -webkit-box-shadow: 4px 4px 4px #000000a8;
    box-shadow: 2px 2px 4px #000000a8;
}

button.main-button.collection__btn.btn.btn-default.catalogue-btn:hover {
    border: 0px;
}