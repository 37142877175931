/* Start section Slider */
/* TODO: ASK MODY */
section.gemaa-slider div#gemaa-slider-carousel.carousel {
    /* height: 100vh; */
}

section.gemaa-slider .img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    display: block;
    height: auto;
    object-fit: cover;
    height: 100vh;
}

.gemaa-slider .item img {
    cursor: auto;
    margin: 0 auto;
    width :100%;
    height: 100vh;
    object-fit: cover;
}
.gemaa-slide__centerd.col-md-12.text-center {
    cursor: pointer;
}
section.gemaa-slider .carousel-control.left,
.carousel-control.right {
    background: transparent;
}

section.gemaa-slider .carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-prev,
.carousel-control .icon-next {
    width: 70px;
    height: 70px;
}

section.gemaa-slider .carousel-control {
    opacity: 1;
}
a.right.carousel-control {
    height: auto;
    display: inline-block;
    overflow: hidden;
    max-height: 236px;
    position: absolute;
    top: 52%;
}

a.left.carousel-control {
    height: auto;
    display: inline-block;
    overflow: hidden;
    max-height: 236px;
    position: absolute;
    top: 52%;
}

section.gemaa-slider .gemaa-slide__caption-holder {
    position: absolute;
    top: 50%;
    cursor: pointer;
    left: 50%;
    transform: translate(-50%, -50%);
}

.left-carousel-button {
    background-image:url("../../../../src/img/slider/arrow-left.png") !important;
    background-size: cover;
    border: 0px;
    background-color: transparent;
    width: 70px !important;
    height: 70px !important;
    
}
/**/
@media (max-width: 767px) {
.left-carousel-button {
    transform: translate(50%,300%) !important;
}
}
.right-carousel-button {
    background-image:url("../../../../src/img/slider/arrow-right.png") !important;
    background-size: cover;
    border: 0px;
    background-color: transparent;
    width: 70px !important;
    height: 70px !important;
}

section.gemaa-slider .gemaa-slide__caption-holder h1 {
    font-family:Montserrat-Medium; 
    text-transform: uppercase;
    font-size: 40px;
    font-weight: bold;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}

a.left.carousel-control {
    float: left;
    left: 0;
}
section.gemaa-slider .gemaa-slider__caption {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 0;
    color: #FFF;
}

span.glyphicon.glyphicon-chevron-right {
    background-image:url("../../../../src/img/slider/arrow-right.png") !important;
    background-size: cover;

}

span.glyphicon.glyphicon-chevron-left {
    background-image:url("../../../../src/img/slider/arrow-left.png") !important;
    background-size: cover;

}
.glyphicon-chevron-left:before {
    content: "" !important;
}

.glyphicon-chevron-right:before {
    content: "" !important;
}



.gemaa-slider__caption h2 {
    font-family: BaskervilleItalic !important;
    font-weight: 500 !important;
}

section.gemaa-slider .carousel-control.left, .carousel-control.right {
    background: transparent;
    position: absolute;
    transform: translate(0, -50%);
}





/* End section Slider */