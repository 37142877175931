.panel{
    border:0;
    box-shadow: inherit;
   }
  
   .panel-body {
      padding: 0px 13px;
          margin-top: 12px;
  
  }
  
   .panel-body {
      border-top-color: #ddd;
      margin-left: 36px;
      background: transparent;
      border:0;
  }
  .panel-heading{
    background: transparent !important;
       padding: 0 15px;
     
      margin-top: 7px;
  }
  
  .panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group{
    border:0;
  }
  
  .wishlist-sidebar h4{
    color: #999999;
      font-size: 16px;
      margin-bottom: 16px;
  
  }
  
  
  .wishlist-sidebar a{
    color: #999999;
    text-decoration:none;
  
  }
  
  .wishlist-sidebar a:hover{
    color: #000000;
  
  }
  
  
  
  .wishlist-sidebar p{
    color: #999999;
         margin-top: -10px;
  
  
  }
  
  .wishlist__main-title h4 , .wishlist__main-title  h4 a{
    color: #000000;
    font-size: 16px;
        margin-bottom: 14px;
  
  }
  
  
  
  .panel-title a{
      color: #000000 !important;
      font-weight: bold;
      font-size: 17px !important;
  
  }
   
  
  span.caret{
        margin-right: 3px;
  
  }
  
  .wishlist-sidebar__search{
        padding: 0 25px;
      margin-top: 22px;
  }
  
  button.wishlist-sidebar__btn-serch.btn.btn-default {
      padding: 12px 22px;
      margin-top: 12px;
      border-radius: 0;
      background: #000;
      color: #fff;
  }
  
  button.wishlist-sidebar__btn-serch.btn.btn-default:hover{
  
      background: #fff;
      color: #000;
          transition: all 0.7s ease;
  
  }
  .wishlist-sidebar__btn {
      padding: 7px;
      width: 100%;
  }
  
  .wishlist-sidebar__search p{
    color: #000000;
  
  }
  div#accordion {
    border-right: 2px solid #999999;
}  