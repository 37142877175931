.view-selector {
    display: inline-block;
    float: right;
    /* margin-right: 100px; */
    margin-top: 23px;
    margin-bottom: 16px;
}

button.view-selector__btn-list,button.view-selector__btn-grid {
    padding: 9px 15px;
    border: 0;
    border-radius: 0;
}



button.view-selector__btn-grid {
   background: #000;
   color:#fff;
       padding-left: 5px;
           font-family: Montserrat-Light;

font-size: 14px;

}


button.view-selector__btn-list {
    background: #fff;
   color:#000;
       padding-left: 5px;
  font-family: Montserrat-Light;

font-size: 14px;
}


button.view-selector__btn-list:focus {
background: #999999;
color:#fff;

}



button.view-selector__btn-grid:focus {
background:#999999;
color:#fff;
}


/*button.view-selector__btn-list:hover {

color:#fff;

}



button.view-selector__btn-grid:hover {
color:#fff;
}

*/


button.view-selector__btn-list:active {
background: #000000;
color:#fff;

}



button.view-selector__btn-grid:active {
background:#000000;
color:#fff;
}


label.btn.btn-default.active {
    background: BLACK;
    COLOR: WHITE;
}






.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child){
    outline: 0;
}


label.btn.btn-default {
    border-radius: 0;
    padding: 12px;
}





