.loyaltyprogram__holder {
	text-align: center;
	border: 2px solid #dfdfdf;
	padding: 14px;
	box-shadow: 4px 3px 0px 3px #c5c5c5;
	height: 310px;
}

.loyaltyprogram__holder h3 {
	font-family: Baskerville-SemiBoldItalic;
	font-size: 24px;
	color: #999999;
	margin-top: 8px;
	text-transform: uppercase;
}

.loyaltyprogram__ContentRight img {
	height: 100%;
	object-fit: cover;
}

.loyaltyprogram__Content p {
	color: #000;
}

.loyaltyprogram__Image {
	position: relative;
}

.loyaltyprogram__Content {
	height: 300px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #dfdfdf;
}

.loyaltyWelecomeImage img {
	height: 300px;
	margin: 0 auto;
	object-fit: cover;
	margin-bottom: 40px;
	width: 100%;
}

.loyaltyprogram__ImageRight img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.loyaltyprogram__Content h4 {
	text-align: left;
	font-family: Montserrat-Medium;
	text-transform: uppercase;
	font-weight: bold;
}

.loyaltyprogram__Content p {
	color: #000;
	font-family: Montserrat-Light;
	font-size: 16px;
	padding: 20px;
}

.loyaltyWelecomeImage h2 {
	margin-bottom: 40px;
}

@media (max-width: 414px) {
	.loyaltyWelecomeImage h2 {
		margin-bottom: 20px;
	}
}


@media (max-width: 414px) {
	.Reverse {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 30px;
		margin-top: 40px;
	}
}

@media (max-width: 414px) {
	.seprator {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 30px;
		margin-top: 40px;
	}
}




.loyaltyprogram__container {
	display: flex;
	padding: 15px;
}

.loyaltyprogram__container-item p {
	font-style: italic;
	color: #737373;
}

.loyaltyprogram__container-item {
	padding: 10px;
	cursor: pointer;
	transition: 0.3s;
	margin: 10px;
	margin-bottom: 40px;
	-webkit-box-shadow: 3px 2px 6px 3px #00000017;
	box-shadow: 3px 2px 6px 3px #00000017;
	height: 400px;
	text-align: center;
}

.redeem-details__name h3 {
	margin: 0px;
	width: 100%;
	font-size: 22px;
	white-space: pre-wrap;
}

.redeem-image__holder {
	border-radius: 1px;
}

.loyaltyprogram__container-item:hover {
	transform: scale(1.1);
}

.redeem-image__holder img {
	width: 100%;
	height: 400px;
	object-fit: contain;
}

.redeem-details__description {
	line-height: 20px;
	font-family: Montserrat-Light;
	margin-top: 10px;
}

.loyaltyprogram__container-item h4 {
	text-transform: uppercase;
	height: 70px;
}

.loyaltyprogram__caption {
	width: 50%;
	margin: 0 auto;
	text-align: center;
}

.loyaltyprogram__holder h5 {
	font-family: BaskervilleItalic;
	font-size: 24px;
	color: #999999;
	margin-bottom: 0;
	margin-top: 22px;
}

.loyaltyprogram__holder p {
	color: #1A1A1A;
	font-family: Montserrat-Light;
	font-size: 16px;
	color: rgba(26, 26, 26, 255);
	text-align: center;
	margin-top: 27px;
	margin-bottom: 27px;
}

.loyaltyprogram__caption {
	width: 70%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 40px;
}

.loyaltyprogram__caption p {
	font-size: 16px;
	margin-top: 40px;
	font-family: Montserrat-Light, MyriadPro-Regular, AdobeInvisFont;
}

@media (max-width: 414px) {
	.loyaltyprogram__caption p {
		margin-top: 20px;
	}
}

.loyaltyprogram__Slide img {
	margin-top: 20px;
	height: 300px;
	object-fit: cover;
	width: 100%;
	margin-bottom: 20px;
}



.loyaltyprogram__MainSlide img {
	height: 550px;
	object-fit: cover;
	width: 100%;
	margin-bottom: 20px;
}

@media (max-width: 414px) {
    .loyaltyprogram__MainSlide img  {
        height:200px;
	}
}



@media (max-width: 414px) {
	.loyaltyprogram__Slide img {
		height: 200px;
	}
}

.loyaltyIcons {
    margin-top: 20px;
    margin-bottom: 40px;
}

.loyaltyIcons img {
	float: left;
	margin-right: 15px;
	width: 85px;
	margin-top: 8px;
}

@media (max-width: 414px) {
	.loyaltyIcons img {
		margin-bottom: 15px;
		width: 75px;
	}
}

.loyaltyIcons h3 {
	font-family: BaskervilleItalic;
	font-size: 26px;
}

@media (max-width: 414px) {
	.loyaltyIcons h3 {
		font-family: BaskervilleItalic;
		font-size: 23px;
		margin-bottom: 5px;
		clear: both;
	}
}
@media (max-width: 375px) {
	.loyaltyIcons h3 {
        font-size: 18px;
        font-weight: bold;

	}
}
@media (max-width: 375px) {
    h2.main-line-title {
        font-size: 22px;
    }
}




.loyaltyreward p {
	line-height: 30px;
	margin-top: 20px;
	font-family: Montserrat-Light, MyriadPro-Regular, AdobeInvisFont;
	text-align: center;
	text-align: center;
	margin: 0 auto;
	display: block;
	font-size: 17px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.loyaltyprogram__btn {
	margin-top: 40px;
	text-align: center;
}

.loyaltyprogram__btn .btn {
	color: #fff;
	background: #000;
	font-family: Montserrat-Light;
	font-size: 14px;
	color: rgba(255, 255, 255, 255);
	text-align: center;
	border-radius: 0;
	border: 0;
	margin-bottom: 38px;
	height: 41px;
}

button.loyaltyprogram__btn-goaccount.btn.btn-default {
	width: 158px;
}

button.loyaltyprogram__btn-Register.btn.btn-default {
	width: 118px;
	margin-right: 11px;
	margin-left: 7px;
}

.loyaltyprogram__btn .btn:hover {
	background: white;
	transition: all 0.8s ease;
	color: black;
	border: 1px solid black;
}

ul.loyaltyrewards__menu li,
ul.loyaltyterms__menu li {
	color: #000;
	margin-bottom: 10px;
	font-family: Montserrat-Light;
	font-size: 16px;
}


ul.loyaltyrewards__menu b , .loyaltyrewards__content b {
	margin-left: 5px;
	margin-right: 5px;

}

.loyaltyrewards h3 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
	font-size: 44px;
	text-transform: uppercase;
	margin-top: 327px;
	font-weight: bold;
	color: #13121214;
}

.loyalty-terms p,
.loyaltyrewards h4 {
	font-family: BaskervilleItalic;
	font-size: 36px;
	text-transform: unset;
	margin-top: 20px;
}

p.loyaltyrewards__content,
.loyaltyrward-calls {
	margin-bottom: 20px;
	font-family: monospace;
	font-family: Montserrat-Light;
	font-size: 16px;
	line-height: 23px;
}

.loyaltyrewards {
	padding: 25px;
}

.loyaltyrward-calls a {
	color: #1a1a1a;
}

.loyaltyrewards h2 {
	width: 100%;
}



.discount-image{
	width: 60px;
}