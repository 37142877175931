
.wishlist-wrapper {
    display: flex;
    justify-content: center;
}

.wishlist__name h4{
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0px;
}

.wishlist__item-holder {
    box-shadow: 3px 3px 3px 3px #888888b8;
    margin-bottom: 30px;
    padding: 10px;
}

.wishlist__item-icon {
    text-align: right;
    cursor: pointer;
}

.wishlist__main-photo img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.wishlist__icons-wrapper {
    margin-top: 20px;
}
.wishlist__range-holder{
    color: #999999;

}
.wishlist__range-holder p{
    margin: 0;
}
.wishlist__range p {
    font-size: 30px;

}
.wishlist__range-currency p {
font-size: 20px;
    margin-top: -19px;

}

.list-produc__id-holder p{
font-size: 17px;
font-weight: bold;
}
.wishlist__range-price p {
    font-size: 48px;
    margin-top: -25px;
    margin-bottom: 2px;
}


.wishlist__colors-img{
        margin-top: 15px;

}

.wishlist__icons-menu {

    margin-top: 18px;
        margin-bottom: 18px;

}
.wishlist__icons-menu a {
    background: #000000;
    padding: 8px 10px;
    margin-right: 4px;
    color: #fff;
    float: left;
    margin-bottom: 16px;
    font-size:19px;
}
.wishlist__data-add p{
        margin-top: 18px;
       margin-bottom: 18px;
        color: #999999;

}
.wishlist__remove{
 color: #999999;
 position: absolute;
 right: 0;
 bottom: -3px;
}
.wishlist i.wishlist__cart-icon.fa.fa-shopping-cart {
    margin-right: 5px;
    float: left;
}
.wishlist__model-info{
    height: 250px;
}


.wishlist__icons span{
      font-family: Montserrat-Medium;
      margin-left: 4px;

}






.wishlist__id-holder p{
    font-weight: bold;
    position: absolute;
    bottom: 2px;
    margin: 0px;
}
.wishlist__bottom-info{
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.wishlist__id-holder span.wishlist__id{
text-transform: uppercase;
}



.wishlist .thumb-img {
    cursor: pointer;
    width: 100px;
    height: 50px;
    object-fit: cover;
}


.list-modal .modal-body{
    padding:0;
}
.list-modal .modal-header{
    border:none;
    padding:0;
}

.modal-content{
    -webkit-box-shadow:inherit;
    border:inherit;
    box-shadow: inherit;
}
/* CSS used here will be applied after bootstrap.css */

/* TODO: ASK MODY BEFORE height 500px */
.carousel-inner > .item > img, .carousel-inner > .item > a > img{
    height: 1150px;
    object-fit: cover;
    width: 100%;
}
.thumb {
    width: 88px;
    cursor: pointer;
    float: left;
    margin: 2px;
     
}
.list-modal img.list-modal__thumb-photo{
    width: 100%;
    /* margin: 2px; */
    object-fit: cover;
    height: 60px;
    margin-top: 18px;
    margin-left: 18px;
    padding-right: 10px;
}
.list-modal img.list-modal__thumb-photo {
    width: 100%;    
}


.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0 !important;
    opacity: 1;
    z-index: 9999999;
    left:inherit;
}

.list-modal__close{
    margin-bottom:10px;
    opacity: 1;
}

/*

.wishlist__icons-menu  a i {
font-size: 27px;
}
*/
.wishlist i.wishlist__fav-icon.fa.fa-star:hover,focus {
color: #fcaf17;
-moz-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
      transition: all 0.5s ease;
        -webkit-transform: rotate(360deg);
    -webkit-transition-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

}
.list-modal .carousel-inner{
    position:absolute;
}
.carousel-control.right, .modal .carousel-control.left {
    background-image:none
}




.list-modal__left-arrow{
    margin-right: 50px;
    margin-top: 20px;
}

.list-modal__right-arrow{
 margin-top: 20px;
padding-left: 15px;
}



