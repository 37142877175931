.HomeBanner img {
    width: 100%;
}



.HomeBanner  {
    margin-bottom: 45px;
}

