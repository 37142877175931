/* Start Section floating header */

section.floating-header.floating-header  nav.navbar.navbar-default {
  background-color: transparent;
  border: 0;
  font-family:Montserrat-Medium; 
  border-radius: 0;
  margin-bottom: 10px;

}

ul.floating-header__important-links.nav.navbar-nav.navbar-right {
  margin-top: 3px;
}


section.floating-header {
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background: white;
  position: relative;
  z-index: 2;
  height: 40px;
}
span.cart_badge{
  padding: 0px !important;
  font-size: 10px !important;
  border-radius: 50% !important;
  width:18px;
  height: 18px;
  line-height: 1.8 !important;
}
section.floating-header ul.navbar-social-media.nav.navbar-nav li a {
  padding: 10px 6px;
}

section.header-menu .header-menu__important-links li a{
  padding-right: 0;
}

section.floating-header .floating-header__social-media  li i {
  font-size: 16px;
  border-radius: 50px;
  background: #1a1a1a;
  color: #ffffff;
  width: 25px;
  height: 25px;
  line-height: 24px;
  text-align: center;
}
section.floating-header ul.floating-header__social-media.nav.navbar-nav li i:hover{
  background: #fff;
  color: #1a1a1a;
  border: 1px solid #1a1a1a;
      transition: all 0.8s ease;

}

section.floating-header ul.floating-header__important-links li a {
  text-transform: uppercase;
  color: #1a1a1a !important;
  /* font-weight: bold; */
  font-size: 13px;
  margin-bottom: 5px;
  font-family: Montserrat-regular;
}


section.floating-header .navbar-nav > li > a{
  padding-top: 6px;

}
/* End Section floating header */