

/* Start contact Side bar */
#showroom__locator {
    padding:0px;
}
#contact__information, #contact_form, #distributors__eg, #fa_q, #showroom__locator {
    padding-top: 0;
    margin-bottom: 50px;
}
.contact-us-sidebar .contact-us__hotline-number p {
    font-size: 16px;
    margin-bottom: 5px;
}
.select__arrow:before {
    position: absolute;
    top: -24px;
    height: 40px;
    width: 1px;
    content: "";
    background: #e9e9e9;
    color: rebeccapurple;
    left: -15px;
}

.distributors__select.select {
    border-bottom: 1px solid #e9e9e9;
    background: white;
    color: #999!important;
    width: 100%;
    text-align: left;
    font-size: 14px;
    border-radius: 0;
}

.distributors-eg__holder.col-md-4 select{
    font-family: Montserrat-Medium !important;
    background: white;
}

.select__arrow {
    position: absolute;
    top: 17px;
    right: 12px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.contact-us-sidebar .contact-us__hotline-number h3 {
    margin: 0;
    color: #999999;
    font-family: Baskerville Italic;
    font-size: 40px;
    margin-left: 3px;
}

.contact-us-sidebar .panel {
    border: 0;
    box-shadow: inherit;
}

.contact-us-sidebar .panel-body {
    padding: 0px 13px;
    margin-top: 12px;
}

.contact-us-sidebar .panel-body {
    border-top-color: #ddd;
    margin-left: 36px;
    background: transparent;
    border: 0;
}

.contact-us-sidebar .panel-heading {
    background: transparent !important;
    padding: 0px;
    margin-top: 7px;
}

.panel-group .panel-heading+.panel-collapse>.panel-body,
.panel-group .panel-heading+.panel-collapse>.list-group {
    border: 0;
}

.contact_FAQ {
    margin:10px
}
.contact_Distributors {
    margin:10px

}
.contact-us-sidebar  h4 {
    color: #999999;
    font-size: 15px;
    margin-bottom: 25px;
}

.contact-us-sidebar  a {
    color: #999999;
    text-decoration: none;
    font-size: 15px;
}

.contact-us-sidebar .contact-us a:hover {
    color: #000000;
}



.contact-us__panel-title h4,
.contact-us__panel-title h4 a {
    font-size: 16px;
    margin-bottom: 25px;
}

.contact-us-sidebar .panel-title a {
    font-weight: bold !important;
    font-size: 15px !important;
}

.contact-us-sidebar span.caret {
    margin-right: 3px;
}


.contact-us-sidebar a.scrollTo:focus {
    color: #000000;
}
.contact-us-sidebar a.scrollTo.active_color {
    color: #000000;
}

.contact-us-sidebar {
    position: relative;
}


/* End contact Side bar */



/* Start faq*/

.faq p.answer {
    margin-bottom: 30px;
    color: #000000 !important;
    width: 75%;
}

.faq h3.question {
    color: #999999;
    font-family: BaskervilleItalic;
    letter-spacing: 1px;
    font-size: 21px;
    margin-bottom: 20px;
}


/* End Faq*/



/* Start  Contact Form */

.contact-form .call-gemma {
    margin-bottom: 25px;
}

.contact-form span.call-gemma__number {
    font-family: Montserrat-Bold;
    font-size: 20px;
}

.contact-form input {
    padding: 7px 11px;
    border: 2px solid #d9d9d9;
    margin-bottom: 15px;
}

.contact-form__text-area {
    padding: 7px 11px;
    border: 2px solid #d9d9d9;
    margin-bottom: 15px;
    width: 70%;
}

.contact-form select.contact-form__country-select {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(../../../img/contactus/select.png);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    background-color: transparent;
    padding: 7px 11px;
    border: 2px solid #d9d9d9;
    margin-bottom: 15px;
}

.contact-form .interested-in.control-group {
    margin-bottom: 10px;
    margin: 0;
}

.contact-form .enter-captcha {
    margin-bottom: 10px;
    margin-top: 25px;
}

.contact-form span.enter-captcha__id {
    font-size: 28px;
    margin-right: 15px;
    position: relative;
    top: 6px;
}



.activeBold{
    color: #131313 !important;
    font-family: Montserrat-Bold;
  }

.contact-form button.contact-form__btn-submit.btn.btn-default:hover {
    background: #fff;
    color: #000;
    transition: all 0.7s ease;
}



/* End  Contact Form */



/* Start Distributors-eg */
.distributors-eg span.distributors-eg__cart.caret {
    float: right;
    margin-top: 9px;
}

.distributors-eg a.btn.btn-default.dropdown-toggle {
    width: 100%;
    text-align: left;
    border: 2px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0;
    padding: 11px;
    color: #999999;
    margin-top: 1px;
}

.distributors-eg__title h4 {
    color: #000000 !important;
    margin-top: 24px;
    margin-bottom: 11px !important;
}

.distributors-eg__btn-group {
    display: inherit;
    text-align: left;
}

.distributors-eg ul.distributors-eg__dropdown.dropdown-menu {
    width: 100%;
    margin-top: 46px;
    border-radius: 0;
    border: 2px solid #dfdfdf;
}

.distributors-eg .open>.dropdown-toggle.btn-default:hover {
    border-color: #dfdfdf;
}

.distributors-eg .btn-group.open .dropdown-toggle {
    box-shadow: inherit;
    background: transparent;
}

.distributors-eg .dropdown-menu {
    box-shadow: inherit;
    border: 0;
}

.distributors-eg li.distributors-eg__aria a:hover {
    background: #cccccc;
    color: #ffffff;
}


.distributors-eg .distributors-eg__dropdown>li>a {
    display: block;
    padding: 3px 8px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #b6b6b6;
    white-space: nowrap;
    margin-bottom: 11px;
}

.distributors-eg .btn-default:active:hover,
.btn-default.active:hover,
.open>.dropdown-toggle .btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open>.dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open>.dropdown-toggle.btn-default.focus {
    color: #b6b6b6;
    background-color: transparent;
    box-shadow: none;
}

 .distributors-eg-box__branch-name p {
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-text-shadow: -10px 0 0px rgba(0, 0, 0, 0);
    -moz-text-shadow: -10px 0 0px rgba(0, 0, 0, 0);
    -ms-text-shadow: -10px 0 0px rgba(0, 0, 0, 0);
    -o-text-shadow: -10px 0 0px rgba(0, 0, 0, 0);
    text-shadow: -100px 0 0px rgba(0, 0, 0, 0);
    overflow: visible;
    text-transform: uppercase;
}

.distributors-eg.distributors-eg-box__address{
    transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    vertical-align: top;
    white-space: nowrap;

}

.distributors-eg-box__address.address-animation {
    display: none;
    transition: all 1.5s ease;
}

.distributors-eg-box__holder:hover .distributors-eg-box__address.address-animation {
    display: block;
    transition: all 2.5s ease;
}
.distributors-eg-box__holder:hover .distributors-eg-box__branch-name.animation p {
    font-weight: bold;
}

.distributors-eg .distributors-eg-box {
    margin-top: 0;
    margin-bottom: 18px;
    font-size: 18px;
}



/* End Distributors-eg */



/* Start Basics*/



/* End Basics*/



.contact-us__right:before {
    position: absolute;
    border-left: 2px solid #9f9f9f;
    left: -23px;
    content: "";
    height: 95%;
    top: 0;
    padding-right: 15px;
}

.contact-information {
    margin-top: 0px;
}

.showroom-locator .showroom-locator__holder {
    font-family: BaskervilleItalic;
    font-size: 18px;
    text-align: center;
    border: 2px solid #dfdfdf;
    padding: 14px;
    box-shadow: 6px 6px 0px 0px #c5c5c5;
    margin-bottom: 25px;
    margin-top: 0px;
    height: 230px;
}
.contact-information .contact-information__line {
    margin-bottom: 1.3em;
    text-align: center;
    display: block;
    position: relative;
    overflow: hidden;
}

.contact-information__line:before,
.contact-information__line:after {
    content: '';
    display: inline-block;
    height: 1px;
    background: #999999;
    width: 50%;
    position: relative;
    vertical-align: middle;
}

.head-quarter__line:before,
.head-quarter__line:after {
    content: '';
    display: inline-block;
    height: 1px;
    background: #999999;
    width: 17%;
    position: relative;
    vertical-align: middle;
}

.contact-information .contact-information__line:before {
    left: -0.5em;
    margin-left: -50%;
}

.contact-information  .contact-information__line:after {
    right: -0.5em;
    margin-right: -50%;
}

.contact-information .contact-information__wrapper {
    font-family: BaskervilleItalic;
    /* font-size: 21px; */
    text-align: center;
    border: 2px solid #dfdfdf;
    padding: 40px;
    box-shadow: 6px 6px 0px 0px #c5c5c5;
    font-weight: bold;
}

span.contact-information__tel,
span.contact-information__fax {
    font-size: 18px;
}

.contact-information .contact-information__mail p {
    font-size: 18px;
    margin-top:5px;
}

.showroom-locator__holder p{
    margin-top:5px;
}
p.contact-information__line,
p.head-quarter__line {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #999;
    font-weight: 100;
}

.contact-information .local-sale__wrapper {
    margin-top: 37px;
    padding: 9px;
    /* text-align: center; */
}

.local-sale__line,
.contact-information__line {
    text-transform: uppercase;
}

.contact-information p.local-sale__line {
    color: #999999;
    margin-bottom: 19px;
}

p.local-sale__tel,
p.local-sale__fax {
    font-family: BaskervilleItalic;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-top: 3px;
}


.showroom-locator__holder{
    border: 2px solid #dfdfdf;
    padding: 21px;
    box-shadow: 6px 6px 0px 0px #c5c5c5;
    margin-top: 24px;
    text-align: center;
    padding-bottom: 87px;
    font-family: BaskervilleItalic;
}

.showroom-locator__branch-name {
    font-family: Montserrat-Medium;
    color: #999;
    text-transform: uppercase;
    font-size:15px;
}
.distributors-eg-box__branch-name {
    font-family: Montserrat-Medium;
    font-size: 15px;
    color: #999;
    font-weight: 100;
}

.distributors-eg .distributors-eg-box__holder {
    border: 2px solid #dfdfdf;
    box-shadow: 6px 6px 0px 0px #c5c5c5;
    /* margin-top: 24px; */
    text-align: center;
    font-family: BaskervilleItalic;
    padding: 11px;
    margin-bottom: 20px;
    font-size: 18px;
    /* padding-bottom: 24px; */
    height: 303px;
}
.contact-information p.head-quarter__tel {
    font-size: 18px;
}



.contact-us__hotline-number {
    margin-top: 70px;
}



.contact-us__title {
    margin-bottom: 33px;
}

.contact-information .head-quarter__wrapper, .showroom-locator__holder {
    border: 2px solid #dfdfdf;
    padding: 21px;
    -webkit-box-shadow: 6px 6px 0 0 #c5c5c5;
    box-shadow: 6px 6px 0 0 #c5c5c5;
    margin-top: 24px;
    text-align: center;
    padding-bottom: 87px;
    font-family: BaskervilleItalic;
}
.shop__title {
    margin-bottom: 33px;
    margin-left: 15px;
    margin-right: 15px;
}





#chartdiv {
    width: 100%;
    height: 100%;
}







.scroll-to-top {
    width: 100px;
    height: 100px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    position: fixed;
    bottom: 150px;
    right: 100px;
    display: none;
    z-index: 2000;
    background-image: url(../../../img/logo2.png);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    background-repeat: no-repeat;
    background-size: contain;
}
.scroll-to-top:before, .scroll-to-top:after {
  content: '';
  position: absolute;
  display: block;
  top: 15px;
  background-color: #fff;
  height: 20px;
  width: 5px;
}
.scroll-to-top:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 28px;
 
}
.scroll-to-top:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 28px;
}
.scroll-to-top:focus {
  outline: none;
}



.menu li a.active {
  color:#000000;
  font-weight: bold;
}


ul.menu li {
    margin-bottom: 25px;
}




section.contact-us {
    padding-bottom: 40px;
}



button.contact-form__btn-submit.btn.btn-default {
    background: #000;
    border-radius: 0;
    padding: 11px;
    text-align: center;
    color: #fff;
    border-radius: 0;
    border: 0;
}

button.contact-form__btn-submit.btn.btn-default:hover {
    border: 1px solid black;
}