.list-product-wrapper {
    box-shadow: rgba(0, 0, 0, 0.3) 5px 1px 14px 0px;
    margin-top: 30px;
    padding: 0;
    border: 2px solid #9d9d9d;
    height: 250px;
    margin-bottom: 30px;
}

.list-product__name h4 {
    padding-left: 15px;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 19px;
    margin-top: 16px;
    font-size: 24px;
    color: #131313;
}
.wishlist__id-holder {
    margin-top: 10px;
}
.list-product__main-photo img {
    width: 100%;
    height: 246px;
    object-fit: cover;
}

.list-product__icons-wrapper {
    margin-top: 35px;
}

.list-product__range-holder {
    color: #999999;
}

.list-product__range-holder p {
    margin: 0;
}

.list-product__range p {
    font-family: BaskervilleItalic;
    font-size: 18px;
    color: rgba(153, 153, 153, 255);
    text-align: left;
    line-height: 18px;
}

.list-product__range-currency p {
    font-size: 20px;
    font-family: BaskervilleItalic;
    font-size: 18px;
}

.list-produc__id-holder p {
    font-size: 17px;
    font-weight: bold;
}

.list-product__range-price p {
    margin-bottom: 8px;
    font-family: BaskervilleItalic;
    font-size: 48px;
    color: rgba(153, 153, 153, 255);
    text-align: left;
    line-height: 34px;
}

.list-product__colors-img {
    float: right;
    margin-top: 15px;
}

a.list-product__icons-link {
    width: 34px;
    height: 34px;
    line-height: 32px;
    text-align: -webkit-auto;
}

.list-product__icons-menu a {
    background: #999999;
    margin-right: 10px;
    color: #fff;
    float: left;
    margin-bottom: 15px;
}

.list-product__id-holder h4 {
    font-weight: bold;
}

.list-product__id-holder span.list-product__id {
    text-transform: uppercase;
}

.list-product .thumb-img {
    cursor: pointer;
    width: 90px;
    height: 50px;
    object-fit: cover;
}

.list-modal .modal-body {
    padding: 0;
}

.list-modal .modal-header {
    border: none;
    padding: 0;
}

.modal-content {
    -webkit-box-shadow: inherit;
    border: inherit;
    box-shadow: inherit;
}

/* CSS used here will be applied after bootstrap.css */

span.grid-produc__id {
    text-transform: uppercase;
}

/* TODO: ASK MODY before height 500px */

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    height: 950px;
    width: 100%;
}

.thumb {
    width: 88px;
    cursor: pointer;
    float: left;
    margin: 2px;
}

.list-modal img.list-modal__thumb-photo {
    width: 100%;
    /* margin: 2px; */
    height: 60px;
    margin-top: 18px;
    margin-left: 18px;
    padding-right: 10px;
}

.list-modal img.list-modal__thumb-photo {
    width: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0 !important;
    opacity: 1;
    z-index: 9999999;
    left: inherit;
}

.list-modal__close {
    margin-bottom: 10px;
    opacity: 1;
}

i.material-icons.list-product__cart-icon {
    font-size: 38px;
    margin-left: 0;
    margin: -2px;
    padding: 0;
    margin-right: 12px !important;
}

.list-product__icons-menu a i {
    font-size: 24px;
    margin-top: 4px;
    margin-left: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.list-product i.material-icons.list-product__fav-icon:active,
focus {
    color: #fcaf17;
    outline: 0;
    transition: all 0.5s ease;
}

.list-modal .carousel-inner {
    position: absolute;
}

.carousel-control.right,
.modal .carousel-control.left {
    background-image: none
}

.list-modal__left-arrow {
    margin-right: 50px;
    margin-top: 20px;
}

.list-modal__right-arrow {
    margin-top: 20px;
    padding-left: 15px;
}

.wishlist__id-holder p {
    font-size: 14px;
    color: rgba(0, 0, 0, 255);
    text-align: left;
    font-family: Montserrat-light;
    line-height: 10px;
    float: left;
}