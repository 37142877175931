

/* Start  section showrooms */

section.showrooms {
    padding: 80px 0;
    background: url(../../../img/showroom/showroom.png);
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    margin-bottom: -50px;
}
button.showroom__btn-btn.btn.btn-default {
  /* color: #1e1e1e; */
  background: transparent;
  border: 2px solid #1e1e1e !important;
  margin-top: 40px !important;
  /* width: auto; */
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 12px;
  font-family: Montserrat-Regular;
  font-size: 16px;
  height: 44px;
  /* line-height: 15px; */
  font-weight: 100;
  /* color: rgba(204, 204, 204, 255); */
  /* display: inline-block; */
  margin: 0;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

button.showroom__btn-btn.btn.btn-default:hover {
    background: #1a1a1a;
    color: #fff;
    border-right: 2px solid #ffffff !important;
    border-bottom: 2px solid #ffffff !important;
  }

/* End  section Showrooms */
