.shop-selector__sort-by {
    background: #000000;
    color: #fff;
    padding: 10px 33px;
    height: 65px;
    position: relative;
}

.shop-selector__sort-by input {
    border-radius: 0px;
}


.shop-selector__sort-by .form-group button {
    border-radius: 0px;
}


.shop-selector__holder .form-group {
    width: 25%;
    position: absolute;
    right: 33px;
    top: 15px;
}

section.shop-selector {
    box-shadow: rgba(0, 0, 0, 0.3) 5px 1px 14px 0px;
    margin-bottom: 12px;
    margin-right: 15px;
}

.shop-selector select {
    display: none;
    -moz-appearance: none;
    background: rgba(0, 0, 0, 0) url(../../../img/shop/dropdown.png) no-repeat scroll right 0 center;
    border: medium none;
    box-shadow: none;
    color: #fff;
    height: inherit;
    -webkit-appearance: none;
    -ms-appearance: none;
    font-family: Montserrat-light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    font-weight: 100;
}

label.shop-selector__result span {
    font-family: Montserrat-light;
    font-size: 14px;
    color: rgba(255, 255, 255, 255);
    text-align: center;
    margin-right: 33px;
}

.shop-selector__result {
    margin-top: 8px;
    float: right;
}

.shop-selector__popular p {
    font-family: Montserrat-light;
    font-size: 14px;
    font-weight: 100;
}

.shop-selector__sortby {
    float: left;
    font-family: BaskervilleItalic;
    margin-top: 6px;
    font-size: 21px;
}

/*.shop-selector__popular{
           margin-left: 50px;
     
    }
    */

.shop-selector__popular a {
    color: #fff;
    text-decoration: none;
}

.shop-selector__price-result {
    margin-left: 37px;
}

.toolbar-products .wrap_title_page {
    float: left;
    margin-top: 4px;
}

.toolbar-products .wrap_title_page {
    font-weight: bold;
    font-size: 30px;
    color: #333;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 10px;
}

.toolbar-products .toolbar-item {
    float: right;
    margin-left: 30px;
}

.toolbar-products .toolbar-item .title-box {
    display: inline-block;
    color: #999;
    text-transform: capitalize;
    vertical-align: middle;
    margin-top: 2px;
    line-height: 38px;
}

.toolbar-products .toolbar-item form,
.toolbar-products .products-per-page {
    display: inline-block;
}

.toolbar-products .toolbar-item.modes select {
    width: 115px;
}

.toolbar-products select {
    padding: 5px 20px;
    width: 160px;
    border: 0;
}

.toolbar-products .chosen-container,
.toolbar-products .chosen-container-single .chosen-single span {
    font-weight: 700;
    color: #555;
    font-size: 14px;
}

.chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.toolbar-products {
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
}

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #fff;
    width: 180px;
}

.select-styled {
    position: absolute;
    top: 3px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 8px 15px;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    font-family: Montserrat-Light;
    font-size: 14px;
}

.select-styled:after {
    content: "";
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 16px;
}

.select-styled:hover {
    background-color: transparent;
}

.select-styled:active,
.select-styled.active {
    background-color: transparent;
}

.select-styled:active:after,
.select-styled.active:after {
    top: 9px;
    border-color: transparent transparent #fff transparent;
}

.select-optionss {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #000;
    color: #fff;
}

.select-optionss li:hover {
    color: #000;
    background: #ffff;
}

.select-optionss li {
    margin: 0;
    padding: 8px 0;
    text-indent: 15px;
    /* border-bottom: 1px solid #fff; */
    -o-transition: all 0.15s ease-in;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    font-family: Montserrat-Light;
    font-size: 14px;
    margin-top: 5px;
}

.select-optionss li[rel="hide"] {
    display: none;
}

button.sort_filter.dropdown-toggle.btn.btn-default {
    background: transparent;
    border: none;
    color: #fff;
    margin-top: 7px;
    font-weight: normal;
    margin-left: 44px;
}

.shop-selector__sort-by .open>.dropdown-menu {
    background: black;
    display: block;
    color: #fff;
}

.shop-selector__sort-by .open>.dropdown-menu>li>a {
    color: #fff;
}

.shop-selector__sort-by .open>.dropdown-menu>li>a {
    color: #fff;
}

.shop-selector__sort-by .dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    background: transparent;
    color: #fff;
    font-weight: bold;
}

button.most_popular_button.btn.btn-default {
    background: transparent;
    border: none;
    color: white;
    margin-top: 7px;
}

button.sidebar__toggle.btn.btn-default {
    width: 100%;
    background: #999999;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: #fff;
    font-family: BaskervilleItalic;
    font-size: 25px;
    text-align: left;
    position: fixed;
    bottom: 0;
    z-index: 4 !important;
}