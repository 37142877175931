.activeBold{
    color: #131313 !important;
    font-family: Montserrat-Bold;
  }
  .user-account .row >.col-md-9 {
    min-height: 400px;
}

.user-border__right .user-account__btn {
  text-align: right;
}