
.confirmation{
    color: rgb(19, 19, 19);
    margin: 100px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    font-family: Montserrat-Regular;
    width: fit-content;
    text-align: center;
    text-transform: uppercase;
}




.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 50%; */
}