section.loyaltyprogram label.btn.btn-default {
    border-radius: 0;
    padding: 8px !important;
    margin-right: 21px;
    border: 0;
    margin-bottom: 30px;
}

.category-toolbar{
    display:flex;
    justify-content: center;
}

.loyaltyprogram__container-item img{
    height: 200px;
    object-fit: contain;
}
.Toastify__toast-container.Toastify__toast-container--top-right a {
    text-decoration: underline !important;
    color: white;
}
.redeem-details__total {
    margin: 40px 0px;
}

.redeem-details__total h4 {
    font-family: Montserrat-Bold;
}

.redeem .modal-body > .container > .row > .col-md-8 {
    border-right: 1px solid #e5e5e5;
}