@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  font-family: Montserrat-Medium;
  color: #1a1a1a;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
btn,
button {
  outline: 0;
}

@font-face {
  font-family: Baskerville;
  /* src: url('../fonts/Baskerville.ttf'); */
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('../fonts/Montserrat-Medium.ttf');
}



@font-face {
  font-family: BaskervilleItalic;
  src: url('../fonts/BaskervilleItalic.ttf');
}

@font-face {
  font-family: Montserrat-Regular;
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-light;
  src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat-ExtraLight;
  src: url('../fonts/Montserrat-ExtraLight.ttf');
}






@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../fonts/Montserrat-SemiBold.ttf');
}



/* Arabic font add by mody*/


@font-face {
  font-family: stc;
  src: url('../fonts/stc.otf');
}


/* Arabic font add by mody*/


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* Start Basics */
.secondry-font {
  font-family: BaskervilleItalic;

}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open>.dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open>.dropdown-toggle.btn-primary.focus {
  background: transparent !important;
}

.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  background: transparent !important;
}

a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

sup {
  vertical-align: super;
  font-size: larger;
  top: 0.1em;
}

.slick-slider.slick-initialized {
  padding: 0px 40px;
}

.collection-product .slick-slider.slick-initialized {
  height: 255px;
}

.collection-product__holder {
  width: 167px !important;
}

.btn-default {
  border: 0px;
  border-radius: 0px;
  transition: 0.3s;
}

.grey-btn {
  color: black;
  border: 0px;
  padding: 8px;
}

.black-btn {
  background: black;
  border: 1px solid black;
  color: white;
}


::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b3b3b3;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b3b3b3;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #b3b3b3;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.textright {
  text-align: right;
  height: 100%;
}


.btn:focus,
input,
button,
select,
textarea {
  outline: 0;
}

.secondry-title h2 {
  font-size: 31px;
  text-transform: uppercase;
  font-family: Baskerville;
  line-height: 44px;
  letter-spacing: 3px;
  margin-bottom: 15px;
}





.main-line-title {
  text-align: center;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  color: #999999;
  letter-spacing: 4px;
  font-family: Baskerville;
  margin-bottom: 30px;

}

.main-line-title:before,
.main-line-title:after {
  content: '';
  display: inline-block;
  height: 2px;
  background: #b9b9b9;
  width: 38%;
  position: relative;
  vertical-align: middle;
}

.main-line-title:before {
  left: -0.5em;
  margin-left: -50%;
}

.main-line-title:after {
  right: -0.5em;
  margin-right: -50%;
}


* {
  margin: 0;
  padding: 0;
}

.no-padding {
  padding: 0;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
  background: transparent !important;
}


/* End  Basics*/




/* Start section main btn */

.main-button button.main-button__btn,
.main-button {
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-right: 2px solid #1e1e1e;
  border-bottom: 2px solid #1e1e1e;
  padding-right: 12px;
  font-family: Montserrat-Regular;
  font-size: 16px;
  height: 44px;
  line-height: 15px;
  font-weight: 100;
  color: #1e1e1e;
  display: inline-block;
  margin: 0;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}


.main-button button.main-button__btn:hover {
  color: #fff;
  background: #cccccc;
}

.main-button button.main-button__btn:focus {
  outline: 0;
  color: #000;
}


/* End section main btn */


/* Start  section Responsive design */

@media (max-width:768px) {
  section.footer {
    padding: 0;
  }


  section.design-your-own .main-button button.main-button-btn {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  section ul.header-menu__important-links {
    text-align: center;
  }

  section.header-menu li.important-links__active:after {
    display: none;
  }
}

@media (max-width:414px) {
  section.gemaa-slider .gemaa-slide__caption-holder h1 {

    font-size: 30px;
  }

  section.gemaa-slider .gemaa-slider-carousel-caption-holder img {
    width: 100%;
    height: 190px;
  }

  section.gemaa-slider .carousel-control .icon-prev,
  .carousel-control .icon-next,
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right {
    top: 44%;
  }


}


@media (min-width: 768px) {
  .modal-dialog {
    margin: 30px auto;
  }
}

/* End  section Responsive design */

.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;

  margin: 10px;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
  background: transparent;
}

/*.control input:checked ~ .control__indicator {
    background: #000000;
}
*/
.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
  /*  background: #000000;
*/
}

.control input:disabled~.control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked~.control__indicator:after {
  display: block;
}

.control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}

.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}

.control--radio input:disabled~.control__indicator:after {
  background: #7b7b7b;
}

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select select:hover~.select__arrow,
.select select:focus~.select__arrow {
  border-top-color: #000;
}

.select select:disabled~.select__arrow {
  border-top-color: #ccc;
}




.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


i.fa {
  font-size: 20px;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url("owl.video.play.png") no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}


.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #615353 !important;
}


section.container.contact-carusal {
  list-style: none;
}

.slick-dots li button {
  display: none !important;
}

.slick-next {
  right: 0 !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-list li a {
  color: red;
  color: #000;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  margin-top: 14px !important;
}

.slick-list li {
  margin-top: 10px;
}


select.contact-form__country-select {
  box-shadow: none !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 29px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.wishlist__title {
  padding: 0px 15px;
}

li.shop-pagination__link-previous {
  display: inline !important;
}

li.shop-pagination__link-next {
  display: inline !important;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 17px;
  top: 25px;
}

span.bm-cross {
  background: #131313;

}

.bm-menu-wrap.header-mobile {
  left: 0 !important;
  top: 0;
}

.bm-menu {
  text-align: left;
  /* margin: 12px 0; */
  padding: 0;
  overflow: hidden !important;
}

.bm-item-list li a {
  color: #131313;
  line-height: 35px;
  text-transform: uppercase;
  font-family: Montserrat-light;


}

nav.bm-item-list {
  padding: 0;
  z-index: 99999999999 !important;
  overflow-y: scroll;
}

.login-now_holder.login-bg {
  background: #131313;
  padding: 0;
  margin-top: 15px;
}

.phone-search .btn.btn-default {
  width: 70px;
  border-radius: 0px;
}

.header-mobile li.floating-header__icon-favicon {
  float: left;
}

.header-mobile .navbar-default .navbar-nav>li>a {
  color: #131313 !important;

}


button.sidebar__toggle.btn.btn-default {
  width: 100%;
  background: #999999;
  box-shadow: none;
  border-radius: 0;
  color: #fff;
}




.slick-next:before,
.slick-prev:before {

  color: #131313 !important;
}

div#lightboxBackdrop {
  z-index: 5;
}

h2.main-line-title {
  width: 100%;
}

.slick-prev:before {
  content: "\2190";
}

.slick-next:before {
  content: "\2192";
}

.slick-next:before,
.slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal {
  z-index: 999999999999;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
}

/*----------------------------------------*/
/*  2. Portfolio  Area
/*----------------------------------------*/
section.projects .grid-product-wrapper.box {
  height: 385px;
}


.section.projects .single-item .pro-content {
  margin-top: 25px;
}

.single-item2 .pro-content {
  margin-bottom: 25px;
}




.single-item:hover,
.homest1 .single-item .pro-img a,
.homest1 .single-item2 .pro-img a {}

.single-item:hover .pro-content-hover {
  opacity: 1;
}

.single-item:hover .pro-img img {
  transform: translate3d(15px, 0, 0) scale(1.1);
}

.homest1 .single-item:hover .pro-img img {
  transform: translate3d(0, 0, 0) scale(1.1);
}

.homest5.hover-st3 .single-item {
  float: none;
}

.homest5.hover-st3 .single-item {
  padding: 0;
}

/*Hover style 2 */
.hover-st2 .pro-content-hover {
  position: absolute;
  left: 0;
  margin: auto;
  background: #000;
  text-align: center;
  z-index: 1;
  height: auto;
  width: 100%;
  margin: 0;
  bottom: -100px;
  right: 0;
  padding-left: 30px;
  opacity: 0;
  top: auto;
}

.hover-st2 .pro-content-hover h3 {
  color: #fff;
}

.hover-st2 .pro-img {
  overflow: hidden;
}

.hover-st2 .pro-img img {
  position: relative;
  transition: all 0.3s ease-in-out;
  top: 0;
}

.single-item {
  /* overflow: hidden; */
  margin-bottom: 20px;

}

.hover-st2 .single-item:hover .pro-img img {
  top: -101px;
}

.hover-st2 .single-item .pro-img img {
  transform: none;
}

.hover-st2 .single-item:hover .pro-img img {
  transform: none;
}

.hover-st2 .single-item:hover .pro-content-hover {
  bottom: 26px;
}

.four-column.hover-st2 .pro-content-hover h3 {
  font-size: 24px;
}


.section.projects .btn-toolbar label {
  border: 0;
}

section.projects label.btn.btn-default {
  border-radius: 0;
  padding: 8px !important;
  margin: 10px;
  border: 0;
  margin-bottom: 30px;
}

.section.projects .btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: 3px;
}



.pro-content-hover {
  text-align: center;
}

.pro-content-hover i {
  font-size: 42px;
}

section.projects {
  padding: 60px 0;
  margin-bottom: 120px;

}

.pro-content-hover a i {
  color: #000;
  margin-top: 10px;
}

.pro-content-hover a {
  color: #000;
}


.pro-content-hover h3 {

  color: #000;
  margin-top: 0;


}




section.projects label.btn.btn-default:hover {
  background: #1a1a1a;
  color: #fff;
  border-radius: 3px;
  transition: 0.2s;
}


h1.no-project {
  text-align: center;
  font-size: 39px;
  color: #999999;
  font-family: BaskervilleItalic;
  text-transform: capitalize;
}






.financial-col {
  height: 400px;
  overflow: scroll;
}

.Financial-Reports-bg {
  background-image: url("../../src/img/About/Financial-Reports.png");
  padding: 80px 0;
  background-size: cover;
  position: relative;
  background-size: cover;
  padding: 70px;
}

.catalogues-bg {
  background-image: url("../../src/img/About/Catalogue.jpg");
  padding: 80px 0;
  background-size: cover;
  position: relative;
  background-size: cover;
  padding: 70px;
}

.company-profile-bg {
  background-image: url("../../src/img/About/Company-Profile3.png");
  padding: 80px 0;
  background-size: cover;
  position: relative;
  background-size: cover;
  padding: 70px;
}

.company-profile_button-container .catalogue-btn {
  font-size: 15px;
}

h1.h1_en_ar {
  font-size: 40px;
  margin-bottom: 10px;
  margin-top: 30px;
  font-family: BaskervilleItalic;
}

.company-profile td {
  line-height: 24px;
}

.company-profile__content p {
  font-family: Montserrat-Regular;
}


.company-profile__content a {
  color: #302a27;
  font-size: 17px;
}


.company-profile_button-container {
  /* display: flex; */
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 120px;
}

.company-profile__content {}

.company-profile__content p {
  color: #000000;
  line-height: 30px;
  font-size: 16px;
}



.FinancialReports .panel-default>.panel-heading:before {
  position: absolute;
  left: 29px;
  width: 6px;
  height: 6px;
  /* border-radius: 50%; */
  background: #444444;
  content: "";
  padding: 0;
  margin-top: 16px;
}

.FinancialReports .accordion {
  border: none;
}

.FinancialReports .accordion__title {
  color: #1a1a1a;
  padding: 50px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #b9b9b930;
  margin-bottom: 30px;
}

.FinancialReports .accordion__item h3 {
  font-size: 34px;
  font-family: monospace;
  font-family: Montserrat-Bold;
  /* margin-top: 0; */
}


/* .FinancialReports .accordion__title:hover {
  background: #1a1a1a;
}

.FinancialReports .accordion__title h3:hover {
color: #fff;
} */

/* 
.FinancialReports:hover .accordion__item h3 {
color:#fff;
}
.FinancialReports:focus .accordion__item h3 {
  color:#000 !important;
  } */



.FinancialReports .panel-title a {
  color: #000000 !important;
  font-weight: inherit !important;
  font-size: 26px !important;
  font-family: BaskervilleItalic;

}
@media (min-width: 768px) {
  .modal-dialog {
    width: 80%;
  }
}

.FinancialReports .panel {
  background: transparent;
}


.FinancialReports .panel-body {
  padding: 0;
}


.FinancialReports .panel-body a {
  font-family: Montserrat-Regular;
  color: #232323;
  margin-top: 12px !important;
  font-size: 16px;
}



.FinancialReports .panel-title {
  margin-bottom: 7px !important;

}


div#accordion__title-0:focus {
  outline: 0;
  border: 0;
}

.map-data {
  margin-top: 100px;
  height: 350px !important;
  margin-left: -15px;

}

.map-holder-content h1 {
  font-family: stc !important;
  margin-bottom: 2px;
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

/* .map-holder-content h1 {
  font-size: 19px;
  font-family: montserrat-medium;
} */
.map-holder {
  min-height: 450px;
}


.box15,
.box17,
.box18 {
  box-shadow: 0 0 5px #7e7d7d;
  text-align: center;
  margin-bottom: 31px;
  height: 270px;
}



/*********************** Demo - 15 *******************/
.box15 {
  position: relative
}

.box15 img {
  width: 100%;
  height: auto
}

.box15 .box-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .5s ease 0s
}

.box15:hover .box-content {
  background-color: rgba(0, 0, 0, 0.8)
}

.box15 .box-content:after,
.box15 .box-content:before {
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  transform: scale(1.5);
  transition: all .6s ease .3s
}

.box15 .box-content:before {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  top: 19px;
  left: 19px
}

.box15 .box-content:after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  bottom: 19px;
  right: 19px
}

.box15:hover .box-content:after,
.box15:hover .box-content:before {
  opacity: 1;
  transform: scale(1)
}

.box15 .title {
  font-size: 22px;
  color: #000;
  margin: 0;
  position: relative;
  top: 20px;
  opacity: 0;
  transition: all 1s ease 10ms
}

.box15:hover .title {
  top: 49%;
  opacity: 1;
  transition: all .5s cubic-bezier(1, -.53, .405, 1.425) 10ms
}

.box15 .title:after {
  content: "";
  width: 0;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 1s ease 0s
}

.box15:hover .title:after {
  width: 80%;
  transition: all 1s ease .8s
}

.box15 .icon {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition-duration: .6s;
  transition-timing-function: cubic-bezier(1, -.53, .405, 1.425);
  transition-delay: .1s;
  margin-bottom: 20px;
}

.box15:hover .icon {
  bottom: 50%;
  opacity: 1
}

.box15 .icon li {
  display: inline-block
}

.box15 .icon li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  border: 1px solid #fff;
  margin-right: 5px;
  transition: all .3s ease-in-out 0s
}

.box15 .icon li a:hover {
  background: #fff;
  color: #000
}

@media only screen and (max-width:990px) {
  .box15 {
    margin-bottom: 30px
  }
}

h3.title a {
  font-size: 15px;
  padding: 10px;

  color: #fff;
}


.box15 .material-icons {

  font-size: 20px;

  margin-top: 8px;

}

.gm-style .gm-style-iw {
  font-weight: 300;
  font-size: 13px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
}

@media (min-width: 1600px) {
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.material-icons-new {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-white {
  webkit-filter: contrast(4) invert(1);
  -moz-filter: contrast(4) invert(1);
  -o-filter: contrast(4) invert(1);
  -ms-filter: contrast(4) invert(1);
  filter: contrast(4) invert(1);
}

.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group{
  height:34px;
}